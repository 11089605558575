import { useInfiniteQuery } from "@tanstack/react-query"
import {
  asReference,
  getResources,
  getResourcesByTypeAsIndex,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  Practitioner,
  PractitionerRole,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getMedsProductConfigurations, MedicationRequestInfo } from "commons"
import { GENERIC_BILLING_TYPE } from "commons/types"
import { getCommonCode } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationRequests = (
  patientId: string,
  category: "medication" | "nutraceutical",
  statusFilter: string[],
  perPage?: number,
  page?: number,
  searchText?: string,
  encounter?: string,
  autoship?: boolean,
) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.medicationRequestList(
    patientId,
    category,
    statusFilter,
    searchText,
    perPage,
    page,
    encounter,
  )

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    MedicationRequestsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = page, signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-prescriptions",
        _patient: patientId,
        category,
        ...(autoship ? { _autoship: `${autoship}` } : {}),
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        _count: perPage?.toString() ?? "20",
        _page: `${pageParam}`,
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: "MedicationRequest", filters, signal })

      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const practitionerRoles = getResourcesByTypeAsIndex<PractitionerRole>(bundle, "PractitionerRole")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")

      const medicationRequestsInfo = medicationRequests.map((mr) => {
        let requesterRef

        if (mr?.requester?.id) {
          const practitioner = practitioners[practitionerRoles?.[mr?.requester?.id]?.practitioner?.id ?? ""]

          if (practitioner?.id) {
            requesterRef = asReference(practitioner)
          }
        }

        return { ...mr, requesterRef }
      }) as MedicationRequestInfo[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        medicationRequestsInfo,
        medicationDispenses,
        medicationKnowledges,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText, perPage, page } },
  })

  const {
    medicationRequestsInfo,
    draftMedicationRequestsCount,
    medicationKnowledges,
    medicationDispenses,
    medsProductConfigurations,
  } = useMemo(() => {
    const mrs = data?.pages.flatMap((page) => page.medicationRequestsInfo)
    const mks = data?.pages.flatMap((page) => page.medicationKnowledges)
    const mds = data?.pages.flatMap((page) => page.medicationDispenses)

    const medsProductConfigurations = getMedsProductConfigurations({
      meds: mrs,
      specifiedQuantity: true,
      billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
    })

    const draftMRs = mrs?.filter((mr) => mr.status === "draft")?.length

    const medicationKnowledges = mks?.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )
    return {
      medicationRequestsInfo: mrs,
      medicationKnowledges,
      medicationDispenses: mds,
      draftMedicationRequestsCount: draftMRs ?? 0,
      total: data?.pages?.[0]?.total ?? 0,
      medsProductConfigurations,
    }
  }, [data?.pages])

  const reloadMedications = () => {
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    medicationRequestsInfo,
    medicationDispenses,
    medicationKnowledges,
    medsProductConfigurations,
    isLoading,
    draftMedicationRequestsCount,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadMedications,
  }
}

type MedicationRequestsQueryData = {
  medicationRequestsInfo: MedicationRequestInfo[]
  medicationDispenses: MedicationDispense[]
  medicationKnowledges: MedicationKnowledge[]
  next: number | undefined
  total: number
}

export { useMedicationRequests }
