import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Invoice } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { FC } from "react"

import { Button } from "../../components/Buttons"

const MedicationCancelDialog: FC<Props> = ({
  cancelFutureOrder,
  cancelReason,
  invoice,
  visible,
  isCancelling,
  updateCancelFutureOrder,
  onConfirm,
  onHide,
  updateCancelReason,
}) => {
  return (
    <Dialog
      closable={true}
      header="Cancel order"
      visible={visible || isCancelling}
      draggable={false}
      dismissableMask={true}
      style={{ width: "50vw" }}
      breakpoints={{ "1280px": "70vw", "1024px": "80vw", "640px": "90vw" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button
            label="Cancel order"
            className="button-primary w-full"
            disabled={!cancelReason}
            loading={isCancelling}
            onClick={onConfirm}
          />
        </div>
      }
    >
      {invoice?.status === "balanced" && (
        <div className="text-orange-500 pb-4 flex items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
          <div className="pl-2 text-sm">
            <div className="leading-4">The order you are trying to cancel is paid</div>
            <div className="leading-4">Invoice identifier: {invoice?.identifier?.[0]?.value}</div>
          </div>
        </div>
      )}
      <label>Please provide a reason</label>
      <InputText
        className="w-full mt-2"
        value={cancelReason}
        onChange={(e) => updateCancelReason(e.target.value as string)}
      />
      <div className="inline-flex items-center mt-3">
        <Checkbox
          name="status"
          checked={cancelFutureOrder === "stop"}
          onChange={() => updateCancelFutureOrder(cancelFutureOrder === "stop" ? "skip" : "stop")}
        />
        <label htmlFor="status" className="p-checkbox-label">
          Cancel all future orders
        </label>
      </div>
    </Dialog>
  )
}
type Props = {
  isCancelling?: boolean
  visible: boolean
  cancelFutureOrder?: string
  invoice?: Invoice
  cancelReason?: string
  updateCancelFutureOrder(mode: "stop" | "skip"): void
  updateCancelReason(reason: string): void
  onHide(): void
  onConfirm(): void
}

export { MedicationCancelDialog }
