import { useMutation, useQueryClient } from "@tanstack/react-query"

import { displayNotificationError } from "errors"
import { useClient } from "api"
import { azureContainer } from "data"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { commonsQueryKeys } from "../query-keys"
import { CodeableConcept, Reference } from "fhir"

const useAzureContainer = (
  containerName: string,
  onSuccess?: (vars: { file: File; subject?: Reference; category?: CodeableConcept[] }) => void,
  onSettled?: () => void,
) => {
  const { uploadFile: clientUploadFile, uploadDocument } = useClient()
  const queryClient = useQueryClient()

  const { mutateAsync: uploadFile, isPending: isUploading } = useMutation({
    mutationFn: async ({
      file,
      subject,
      category,
    }: {
      file: File
      subject?: Reference
      category?: CodeableConcept[]
    }): Promise<string> => {
      const fileUrl =
        containerName === azureContainer.docs
          ? await uploadDocument(file, containerName, subject ?? {}, category ?? [])
          : await clientUploadFile(file, containerName)

      return fileUrl
    },
    onError: (error: CustomError, { file: { name, type, size } }) => {
      displayNotificationError(registerErrorTrace(error, { file: { name, type, size } }))
    },
    onSuccess: (_, vars) => {
      switch (containerName) {
        case azureContainer.photos:
          queryClient.invalidateQueries({ queryKey: commonsQueryKeys.signed })
          break
      }
      onSuccess?.(vars)
    },
    onSettled,
  })

  return { uploadFile, isUploading }
}

export { useAzureContainer }
