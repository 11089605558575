import { Bundle, CodeableConcept, Parameters, Reference, ResourceObject } from "fhir"
import { useMemo } from "react"

import { useAuth } from "security"

import { Client } from "../client"

const useClient = () => {
  const { user } = useAuth()

  const client = useMemo(() => new Client(user), [user])

  const operationRequest = <T extends ResourceObject>({
    endpoint,
    method,
    operation,
    id,
    parameters,
    filters,
  }: OperationRequestArgs) => client.operationRequest<T>(endpoint, method, id, parameters, filters, operation)

  const read = <T extends ResourceObject>({ endpoint, id, filters, operation, signal }: ReadArgs) =>
    client.read<T>(endpoint, id, filters, operation, signal)

  const search = ({ endpoint, filters, operation, signal }: SearchArgs) =>
    client.search(endpoint, filters, operation, signal)

  const create = <T extends ResourceObject>(endpoint: string, resource: T) => client.create(endpoint, resource)

  const update = <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
    client.update(endpoint, id, resource)

  const patch = <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
    client.patch(endpoint, id, resource)

  const remove = <T extends ResourceObject>(endpoint: string, id: string) => client.remove<T>(endpoint, id)

  const transaction = (bundle: Bundle) => client.transaction(bundle)

  const uploadFile = (file: File, containerName: string) => client.uploadFile(file, containerName)

  const getSignedUrl = (url: string, signal?: AbortSignal) => client.getSignedUrl(url, signal)

  const uploadDocument = (file: File, containerName: string, subject: Reference, category: CodeableConcept[]) =>
    client.uploadDocument(file, containerName, subject, category)

  return {
    read,
    search,
    create,
    update,
    patch,
    remove,
    transaction,
    uploadFile,
    getSignedUrl,
    operationRequest,
    uploadDocument,
  }
}

interface OperationRequestArgs {
  endpoint: string
  method: "GET" | "POST" | "PUT"
  operation: string
  id?: string
  parameters?: Parameters
  filters?: URLSearchParams
}

interface ReadArgs {
  endpoint: string
  id?: string
  filters?: URLSearchParams
  operation?: string
  signal?: AbortSignal
}

interface SearchArgs {
  endpoint: string
  filters?: URLSearchParams
  operation?: string
  signal?: AbortSignal
}

export { useClient }
