import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, MedicationRequest, Reference } from "fhir"
import { FC, useState } from "react"

import { getMedsProductConfigurations, SkeletonLoader, StackedListContainer } from "commons"
import { useProductPrices } from "commons/hooks"
import {
  MedicationDataItem,
  MedicationDetails,
  prescriptionItemModel,
  useMedicationKnowledge,
  useMedicationRequestDataBind,
} from "commons/meds"
import { GENERIC_BILLING_TYPE } from "commons/types"
import { MEDICATION_CATALOG } from "data"
import { getCommonCode } from "utils"

const CPMedicationsDetails: FC<Props> = ({ medicationRequests, organizationId, category, className }) => {
  const medsProductConfigurations = getMedsProductConfigurations({
    meds: medicationRequests,
    billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
  })
  const sku = medsProductConfigurations?.map(({ code }) => getCommonCode({ codes: code })) ?? []

  const [selectedMed, setSelectedMed] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const { isLoading, mksBySku } = useMedicationKnowledge({
    enabled: !!sku.length,
    medCodes: sku.join(","),
    hasCIDInOrg: organizationId,
    category,
  })

  const { productPrices } = useProductPrices({
    organizationId,
    productsConfigurations: medsProductConfigurations,
  })

  const { medicationRequestData } = useMedicationRequestDataBind(medicationRequests, mksBySku, productPrices)

  if (isLoading) return <SkeletonLoader repeats={sku.length} loaderType="two-lines" />

  return (
    <>
      {category === MEDICATION_CATALOG.NUTRA ? (
        <div className="flex flex-col ml-4">
          {medicationRequestData.map((item) => (
            <MedicationDataItem
              key={item.medicationRequestInfo.id}
              medicationRequest={item.medicationRequestInfo}
              medicationDispense={item.medicationDispense}
              medicationKnowledge={item.medicationKnowledge}
              pricePerUnit={item.pricePerUnit}
              onClick={() => setSelectedMed({ mk: item.medicationKnowledge, mr: item.medicationRequestInfo })}
              showDispense
              showInstructions
              showPackagingType
              showStatus
            />
          ))}
        </div>
      ) : (
        <StackedListContainer
          data={medicationRequestData}
          itemModelBuilder={(data) =>
            prescriptionItemModel({
              mrData: data,
              showAsExternalAction: true,
              preview: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
              externalAction: [
                {
                  label: "Preview",
                  icon: <FontAwesomeIcon icon={faEye} />,
                  command: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
                },
              ],
            })
          }
          className={className}
        />
      )}
      <MedicationDetails
        medication={
          selectedMed
            ? {
                ...selectedMed?.mr,
                medicationKnowledge: selectedMed?.mk,
                intent: selectedMed.mr?.intent as string,
                status: selectedMed.mr?.status as string,
                subject: selectedMed.mr?.subject as Reference,
              }
            : undefined
        }
        onHide={() => setSelectedMed(undefined)}
      />
    </>
  )
}

type Props = {
  medicationRequests: MedicationRequest[]
  organizationId: string
  category: MEDICATION_CATALOG
  className?: string
}

export { CPMedicationsDetails }
