import { useMutation } from "@tanstack/react-query"
import { Parameters, Reference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons/types"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useValidateEmailEdit = () => {
  const { operationRequest } = useClient()

  const validateEmail = async ({ emailToCheck, subject }: { emailToCheck?: string; subject: Reference }) => {
    if (!emailToCheck) return false

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "new-email",
          value: {
            string: emailToCheck,
          },
        },
      ],
    }

    try {
      const results = await operationRequest<Parameters>({
        endpoint: `${subject.resourceType}/${subject.id}`,
        method: "POST",
        operation: "validate-edit-email",
        parameters,
      })
      return results.parameter?.find((param) => param.name === "exist")?.value?.boolean ?? true
    } catch (error) {
      if ((error as CustomError)?.cause?.message?.includes("already in use")) return true
      else displayNotificationError(registerErrorTrace(error as CustomError, { emailToCheck }))
    }
  }

  const {
    mutateAsync: validateEmailEdit,
    isPending,
    data,
    isIdle,
  } = useMutation({
    mutationKey: ["validate-email-edit"],
    mutationFn: validateEmail,
    onError: (error: CustomError, email) => {
      displayNotificationError(registerErrorTrace(error, { email }))
    },
  })

  return { validateEmailEdit, isValidatingEmail: isPending, exists: data ?? false, hasBeenChecked: !isIdle }
}

export { useValidateEmailEdit }
