import { faUser } from "@fortawesome/pro-light-svg-icons"
import { faHouseCrack } from "@fortawesome/pro-regular-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { Coverage } from "fhir"

import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { ReplaceFormProvider } from "commons/context"
import { ProfileSection } from "data"
import { useLoginContext } from "security"
import { getBadgeColor, strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-renders"

import { useCoverages, useCreateCoverage, useCancelCoverage } from "../hooks"
import { CoverageForm } from "./CoverageForm"
import { coverageValidationSchema, getCoverageInitialValues, sanitizeCoverage } from "./validations"
import { CoverageData } from "../types"

const CoveragesContainer = () => {
  const { loggedInPatientId } = useLoginContext()

  const { showSlide, initialValue, deleteIndex, add, edit, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: getCoverageInitialValues(loggedInPatientId),
  })

  const { coverages, isLoading } = useCoverages(loggedInPatientId)
  const { createCoverage, isAdding } = useCreateCoverage(reset, loggedInPatientId)
  const { cancelCoverage, isCanceling } = useCancelCoverage(() => setDeleteIndex(undefined), loggedInPatientId)

  const onSubmit = (data: Coverage) => createCoverage(sanitizeCoverage(data))

  return (
    <InformationCardContainer
      id={ProfileSection.INSURANCE}
      title="Insurance Information"
      showEdit={coverages.length > 0}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
      className="profile-card-section"
    >
      {isLoading ? (
        <SkeletonLoader repeats={1} loaderType="two-lines" />
      ) : (
        <ReplaceFormProvider>
          <DataContainerSlideoverForm
            hasData={!!coverages?.length}
            showSlide={showSlide || isAdding}
            formTitle="Insurance"
            iconDataNotFound={faHouseCrack}
            formInitialValue={initialValue}
            validationSchema={coverageValidationSchema}
            onSubmit={onSubmit}
            onCancel={reset}
            form={<CoverageForm />}
            showAddButton={false}
            onButtonAddClick={add}
          >
            <StackedListContainer
              data={coverages ?? []}
              itemModelBuilder={(item) =>
                coverageModelBuilder(
                  item,
                  () => edit(item),
                  () => setDeleteIndex(item.id),
                )
              }
            />
            <ConfirmDialog
              confirmText="Are you sure you want to cancel this insurance?"
              actionName="Cancel"
              visible={deleteIndex !== undefined || isCanceling}
              isLoading={isCanceling}
              onConfirm={() => cancelCoverage(deleteIndex as string)}
              hideDialog={() => setDeleteIndex(undefined)}
            />
          </DataContainerSlideoverForm>
        </ReplaceFormProvider>
      )}
    </InformationCardContainer>
  )
}

const coverageModelBuilder = (
  coverage: CoverageData,
  onEdit: () => void,
  onDelete: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Carrier", value: strCapitalize(coverage.payor?.[0]?.display ?? "") }],
    },
    ...(coverage.payor[0].display
      ? [
          {
            lineItems: [
              {
                name: "Subscriber",
                value: coverage.beneficiary.id === coverage.subscriber?.id ? "Self" : coverage.subscriber?.display,
                icon: faUser,
              },
              {
                name: "Subscriber ID",
                value: `#${coverage.subscriberId}`,
              },
              /*  {
                name: "Payor Address",
                value: getStringAddress(coverage.payor?.[0]?.address),
                icon: faLocationDot,
              }, */
            ],
          },
        ]
      : []),
  ],
  badge: getBadgeColor(coverage.status),
  menu: defaultEditRemoveMenu({ onEdit, onDelete, disabled: coverage.status === "cancelled" }),
})

export { CoveragesContainer }
