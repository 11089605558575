import { useInfiniteQuery } from "@tanstack/react-query"
import { DocumentReference, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { patientKeys } from "../query-keys"

const usePatientDocuments = (patientId: string) => {
  const { search } = useClient()
  const queryKey = patientKeys.documents(patientId)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientDocumentsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        "status:not": "entered-in-error",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/DocumentReference`, filters, signal })

      const documents = getResources(bundle) as DocumentReference[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { documents, next, total: bundle?.total ?? 0 }
    },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    meta: { context: { queryKey, patientId } },
  })

  const { documents, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.documents)
    const count = newData?.length

    return {
      documents: newData,
      count,
    }
  }, [data?.pages])

  return {
    documents,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetchDocs: refetch,
  }
}

type PatientDocumentsQueryData = {
  documents: DocumentReference[]
  next: number | undefined
  total: number
}

export { usePatientDocuments }
