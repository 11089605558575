import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, Coding, Duration, MedicationKnowledge, Reference } from "fhir"
import { useId } from "react"

import { getProductKey, SkeletonLoader, useSidebarContext } from "commons"
import { useProductPrices } from "commons/hooks"
import { dispenseInterval, MedicationKnowledgeDetails, useMedicationKnowledge } from "commons/meds"
import { GENERIC_BILLING_TYPE } from "commons/types"
import { useMedicationCatalogs } from "medication-requests"
import { CartMedData } from "orders"
import { getBasePrice, getCommonCode, getMoneyCurrencyAlt } from "utils"

import { useMedCatalogReducerState } from "../../hooks"
import { MedicationCatalogListFilters } from "./MedicationCatalogListFilters"
import { MedicationKnowledgeCard } from "./MedicationKnowledgeCard"
import { OverviewFooter } from "./OverviewFooter"

const MedicationKnowledgeList = ({
  onAddMK,
  onEditMK,
  onDelete,
  addedMeds,
  isAdding,
  isDeleting,
  isDisabled,
}: Props) => {
  const { activeCatalogs, organizationId } = useSidebarContext()

  const { selectedCatalog, searchFilter, selectedMK, setSelectedCatalog, setSearchFilter, setSelectedMK } =
    useMedCatalogReducerState(activeCatalogs?.[0])

  const { catalogs, isLoading: isLoadingCatalogs } = useMedicationCatalogs(activeCatalogs)
  const { medicationKnowledge, mkProductConfigurations, isLoading } = useMedicationKnowledge({
    filter: searchFilter,
    catalogId: selectedCatalog ?? activeCatalogs.join(","),
  })
  const { productPrices, isLoading: isLoadingPrice } = useProductPrices({
    organizationId,
    productsConfigurations: mkProductConfigurations,
  })

  const getMKPrice = (mkCode?: CodeableConcept) => {
    const productKey = getProductKey({
      code: mkCode?.coding,
      billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
    })
    const chargeItemDef = productPrices?.[productKey]
    const cost = getBasePrice(chargeItemDef?.propertyGroup?.[0].priceComponent)?.amount
    return { value: cost?.value, currency: cost?.currency ?? "USD" }
  }

  const addedMedCodes = Object.keys(addedMeds)
  const isMedToAdd = (code?: Coding[]) => !addedMedCodes.includes(getCommonCode({ codes: code }))

  const onSelectMK = (mk: MedicationKnowledge, unitsCount: number, duration?: Duration) => {
    const mkCatalog = catalogs.find((c) => c.id === mk.catalogHeader?.[0].id)
    onAddMK(mk, unitsCount, mkCatalog?.author?.[0] as Reference, duration ?? dispenseInterval[0].value)
  }

  const onEditMKDispense = (mk: MedicationKnowledge, unitsCount: number, duration?: Duration) => {
    const mkCatalog = catalogs.find((c) => c.id === mk.catalogHeader?.[0]?.id)
    onEditMK(mk, unitsCount, mkCatalog?.author?.[0] as Reference, duration ?? dispenseInterval[0].value)
  }

  const onDeleteMK = (mrId: string) => {
    onDelete(mrId)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader repeats={20} key={loaderKey} loaderType="mkCard" />

  return (
    <div className="flex flex-col h-full p-1">
      <MedicationCatalogListFilters
        isLoading={isLoading || isLoadingCatalogs}
        catalogs={catalogs}
        catalog={selectedCatalog}
        setSearchFilter={setSearchFilter}
        setSelectedCatalog={setSelectedCatalog}
      />
      {isLoading ? (
        <div className={containerLayoutClass}>{loader()}</div>
      ) : !medicationKnowledge.length ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications found</p>
        </div>
      ) : (
        <div className={containerLayoutClass}>
          {medicationKnowledge.map((mk) => {
            const mkPrice = getMKPrice(mk.code)
            const medData = addedMeds[getCommonCode({ codes: mk.code?.coding })]

            return (
              <MedicationKnowledgeCard
                key={mk.id}
                medicationKnowledge={mk}
                pricePerUnit={mkPrice}
                isLoadingPrice={isLoadingPrice}
                medData={medData}
                blockAdd={!mkPrice.value || !isMedToAdd(mk.code?.coding) || isDisabled}
                isProcessing={isAdding || isDeleting}
                onAdd={onSelectMK}
                onEditDispense={onEditMKDispense}
                onDelete={onDeleteMK}
                onClick={() => setSelectedMK(mk)}
              />
            )
          })}
        </div>
      )}
      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        pricePerUnit={selectedMK && getMKPrice(selectedMK.code)}
        footer={
          <OverviewFooter
            overviewStyle
            price={`${getMoneyCurrencyAlt(getMKPrice(selectedMK?.code).currency)}${getMKPrice(
              selectedMK?.code,
            ).value?.toFixed(2)}`}
            blockAdd={!getMKPrice(selectedMK?.code).value || !isMedToAdd(selectedMK?.code?.coding) || isDisabled}
            isProcessing={isAdding || isDeleting}
            medData={addedMeds[getCommonCode({ codes: selectedMK?.code?.coding })]}
            onAdd={(units, duration) => onSelectMK(selectedMK as MedicationKnowledge, units, duration)}
            onEdit={(units, duration) => onEditMKDispense(selectedMK as MedicationKnowledge, units, duration)}
            onDelete={onDeleteMK}
            onHide={() => setSelectedMK(undefined)}
          />
        }
      />
    </div>
  )
}

const containerLayoutClass =
  "grid grid-flow-row-dense grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 xl:gap-10 overflow-auto overflow-x-hidden pb-8 px-1"

type Props = {
  onAddMK(mk: MedicationKnowledge, unitsCount: number, catalogAuthor: Reference, duration: Duration): void
  onEditMK(mk: MedicationKnowledge, unitsCount: number, catalogAuthor: Reference, duration: Duration): void
  onDelete(mrId: string): void
  addedMeds: Record<string, CartMedData>
  isAdding?: boolean
  isDeleting?: boolean
  isDisabled?: boolean
}

export { MedicationKnowledgeList }
