import { ScheduleAppointmentProvider } from "../context"
import { AppointmentCalendarSection } from "./AppointmentCalendarSection"
import { AppointmentParticipantsSection } from "./AppointmentParticipantsSection"
import { AppointmentSlotsSection } from "./AppointmentSlotsSection"

const AppointmentForm = ({ organizationId, hideExtraParticipantFields }: Props) => {
  return (
    <ScheduleAppointmentProvider>
      <div className="relative p-fluid grid grid-flow-row-dense @3xl:grid-flow-col-dense gap-6 mb-3 p-2">
        <AppointmentCalendarSection organizationId={organizationId} />
        <AppointmentSlotsSection />
        <AppointmentParticipantsSection hideExtraParticipantFields={hideExtraParticipantFields} />
      </div>
    </ScheduleAppointmentProvider>
  )
}

type Props = {
  hideExtraParticipantFields?: boolean
  organizationId: string
}

export { AppointmentForm }
