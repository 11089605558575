import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { startTransition, useId, useReducer, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSearchParams } from "react-router-dom"

import { SearchWithStatus, SkeletonLoader, StackedListContainer } from "commons"
import { invoiceStatusCodes } from "data"
import { useLoginContext } from "security"

import { useInvoices, usePrintInvoice } from "../hooks"
import { invoiceModelBuilder } from "./invoiceModelBuilder"

const InvoiceListContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { loggedInPatientId } = useLoginContext()
  const [printItem, setPrintItem] = useState<string>()

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const { statusFilter, searchFilter, updateFilter, updateSearchFilter } = useReducerState()
  const { invoiceData, isLoading, fetchNextPage, hasNextPage } = useInvoices({
    patientId: loggedInPatientId,
    statusFilter,
    searchText: searchFilter,
  })

  const { printInvoice, isPrinting } = usePrintInvoice()

  const showSelectedInvoice = (invoiceId: string) => {
    searchParams.set("invoiceId", invoiceId)
    setSearchParams(searchParams)
  }

  return (
    <div className="module-container h-full relative overflow-hidden p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:h-12 w-full mb-3">
        <SearchWithStatus
          placeholder="Search invoice"
          options={invoiceStatusCodes}
          selectedItems={statusFilter}
          onStatusCheck={updateFilter}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchFilter(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!invoiceData?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No invoices found</p>
            </div>
          ) : (
            <div className="bg-white h-full overflow-auto pb-16">
              <InfiniteScroll
                loadMore={() => fetchNextPage()}
                hasMore={hasNextPage}
                useWindow={false}
                loader={loader()}
              >
                <StackedListContainer
                  data={invoiceData}
                  itemModelBuilder={(item) =>
                    invoiceModelBuilder({
                      invoiceData: item,
                      showInvoice: () => showSelectedInvoice(item.invoice.id as string),
                      showAsExternal: false,
                      printInvoice: (id) => {
                        setPrintItem(id)
                        printInvoice(id)
                      },
                      isPrinting: isPrinting && printItem === item.invoice.id,
                    })
                  }
                  itemsClassName="py-4"
                />
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const initialState = {
  statusFilter: ["balanced", "issued", "draft"],
  searchFilter: undefined,
  selectedInvoiceId: undefined,
} as State

const reducer = (
  state: State,
  { type, payload }: { type: "update-status-filter" | "update-search-filter"; payload?: string[] | string },
) => {
  switch (type) {
    case "update-status-filter":
      return {
        ...state,
        statusFilter: payload as string[],
        selectedInvoiceId: undefined,
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
        selectedInvoiceId: undefined,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ statusFilter, searchFilter }, dispatch] = useReducer(reducer, initialState)

  const updateFilter = (statusArray: string[]) => {
    dispatch({ type: "update-status-filter", payload: statusArray })
  }

  const updateSearchFilter = (searchText: string) => {
    dispatch({ type: "update-search-filter", payload: searchText })
  }

  return { statusFilter, searchFilter, updateFilter, updateSearchFilter }
}

type State = {
  statusFilter: Array<string>
  searchFilter: string | undefined
}

export { InvoiceListContainer }
