import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import {
  Coding,
  getResources,
  Identifier,
  isMedicationKnowledge,
  isMedicationRequest,
  ParametersParameterArrayArray,
  ServiceRequest,
  type Bundle,
  type ChargeItemDefinition,
  type Duration,
  type MedicationKnowledge,
  type MedicationRequest,
  type Money,
  type Parameters,
  type Quantity,
} from "fhir"

import { BILLING_TYPES_CODES, formatsByTypes, MED_FEE_TYPE } from "data"
import { getBasePrice, getBillingTypeCode, getCommonCode, getCommonCoding } from "utils"

import { getFeeType } from "./meds"
import {
  FieldErrorType,
  GENERIC_BILLING_TYPE,
  PRODUCT_CONFIGURATION_BILLING_TYPE,
  type ProductConfiguration,
  type ProductConfigurationToRequest,
} from "./types"

const SKU_SYSTEM = "http://chartedhealth.com/fhir/identifiers/sku"

const getPDSkuValue = (identifiers: Identifier[] | undefined) =>
  identifiers?.find((id) => id.system === SKU_SYSTEM)?.value

const getFieldError = (fields: FieldErrorType[]): string => {
  const [key, value] = fields[0]
  if (typeof value === "string") return key

  if (Array.isArray(value)) {
    const index = value.findIndex((d) => d)
    return `${key}[${index}].${getFieldError(Object.entries(value[index]))}`
  }

  return `${key}.${getFieldError(Object.entries(value))}`
}

const getOrderDate = (order?: ServiceRequest) =>
  order?.occurrence?.dateTime || order?.occurrence?.Period || order?.authoredOn
    ? format(
        parseISO(
          (order?.occurrence?.dateTime ??
            order.occurrence?.Period?.end ??
            order.occurrence?.Period?.start ??
            order.authoredOn) as string,
        ),
        formatsByTypes.LONG_DATE,
      )
    : "unspecified"

const DEFAULT_QUANTITY = 1

type BaseProcessMedicationArgs = {
  specifiedQuantity?: boolean
  specifiedMedFrequency?: boolean
  billingType: PRODUCT_CONFIGURATION_BILLING_TYPE
  medsProductConfigurations: ProductConfigurationToRequest[]
  uniqueConfigurations: Set<string>
}

type ProcessMedicationRequestArgs = BaseProcessMedicationArgs & {
  med: MedicationRequest
  referenceMedFrequency?: Duration
}

type ProcessMedicationKnowledgeArgs = BaseProcessMedicationArgs & {
  med: MedicationKnowledge
  referenceMedFrequency?: Duration
  quantities: number[]
}

const configurationToKey = ({ code, billingType, quantity, frequency }: ProductConfigurationToRequest) =>
  `${code.code}-${billingType}-${quantity}-${frequency?.value}${frequency?.unit}`

const addUniqueConfiguration = (
  configuration: ProductConfigurationToRequest | undefined,
  uniqueConfigurations: Set<string>,
  medsProductConfigurations: ProductConfigurationToRequest[],
) => {
  if (configuration) {
    const key = configurationToKey(configuration)
    if (!uniqueConfigurations.has(key)) {
      uniqueConfigurations.add(key)
      medsProductConfigurations.push(configuration)
    }
  }
}

const processMedicationRequest = ({
  med,
  specifiedQuantity,
  specifiedMedFrequency,
  billingType,
  medsProductConfigurations,
  uniqueConfigurations,
}: ProcessMedicationRequestArgs) => {
  const embeddedBillingType = getBillingTypeCode(med)
  const configuration = buildProductConfigurationToRequest({
    code: med.medication?.CodeableConcept?.coding,
    billingType:
      embeddedBillingType && billingType !== PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH
        ? getGenericBillingType(embeddedBillingType)
        : billingType,
    quantity: specifiedQuantity ? med?.dispenseRequest?.quantity?.value ?? DEFAULT_QUANTITY : undefined,
    frequency: specifiedMedFrequency ? (med?.dispenseRequest?.dispenseInterval as Quantity) : undefined,
  })

  addUniqueConfiguration(configuration, uniqueConfigurations, medsProductConfigurations)
}

const processMedicationKnowledge = ({
  med,
  specifiedQuantity,
  specifiedMedFrequency,
  referenceMedFrequency,
  billingType,
  medsProductConfigurations,
  quantities,
  uniqueConfigurations,
}: ProcessMedicationKnowledgeArgs) => {
  const frequency = specifiedMedFrequency && referenceMedFrequency ? referenceMedFrequency : undefined

  quantities.forEach((quantity) => {
    const configuration = buildProductConfigurationToRequest({
      code: med?.code?.coding,
      billingType,
      quantity: specifiedQuantity ? quantity : undefined,
      frequency,
    })

    addUniqueConfiguration(configuration, uniqueConfigurations, medsProductConfigurations)
  })
}
const getMedsProductConfigurations = ({
  meds,
  specifiedQuantity = false,
  referenceQuantities,
  specifiedMedFrequency = false,
  referenceMedFrequency,
  billingType = PRODUCT_CONFIGURATION_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
}: {
  meds?: Array<MedicationRequest | MedicationKnowledge>
  specifiedQuantity?: boolean
  referenceQuantities?: number[]
  specifiedMedFrequency?: boolean
  referenceMedFrequency?: Duration
  billingType?: PRODUCT_CONFIGURATION_BILLING_TYPE
}): ProductConfigurationToRequest[] => {
  if (!meds?.length) return []

  const quantities = [DEFAULT_QUANTITY, ...(referenceQuantities ?? [])]
  const uniqueConfigurations = new Set<string>()

  const medsProductConfigurations: ProductConfigurationToRequest[] = []

  meds.forEach((med) => {
    if (isMedicationRequest(med)) {
      processMedicationRequest({
        med,
        specifiedQuantity,
        specifiedMedFrequency,
        billingType,
        medsProductConfigurations,
        uniqueConfigurations,
      })
    } else if (isMedicationKnowledge(med)) {
      processMedicationKnowledge({
        med,
        specifiedQuantity,
        specifiedMedFrequency,
        referenceMedFrequency,
        billingType,
        medsProductConfigurations,
        quantities,
        uniqueConfigurations,
      })
    }
  })

  return medsProductConfigurations
}

// add more variables to this array
// to include extra product configuration options
const extraConfigurationData = [
  {
    variable: "quantity",
  },
  {
    variable: "frequency",
  },
]

const formatProductPricesResponse = (paramatersBundle: Parameters) => {
  const {
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: practiceOrInsuranceChargeItemDefinitions,
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: patientChargeItemDefinitions,
  } = getChargeItemDefinitions(paramatersBundle)

  const practiceOrInsuranceChargeItemDefinitionsByApplicability = indexChargeItemDefinitionsByApplicability(
    practiceOrInsuranceChargeItemDefinitions,
    GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
  )

  const patientChargeItemDefinitionsByApplicability = indexChargeItemDefinitionsByApplicability(
    patientChargeItemDefinitions,
    GENERIC_BILLING_TYPE.BILL_PATIENT,
  )

  return {
    ...practiceOrInsuranceChargeItemDefinitionsByApplicability,
    ...patientChargeItemDefinitionsByApplicability,
  }
}

const NOT_FOUND_KEY = "NOT_PRODUCT_CODE"
const indexChargeItemDefinitionsByApplicability = (
  chargeItemDefinitions: ChargeItemDefinition[],
  billingType: GENERIC_BILLING_TYPE,
) => {
  if (!chargeItemDefinitions.length) return {}

  let chargeItemDefinitionsByApplicability: Record<string, ChargeItemDefinition> = {}

  chargeItemDefinitions.forEach((chargeItemDefinition) => {
    const kvStore = productToKVStore(chargeItemDefinition, billingType)
    if (!kvStore) return
    chargeItemDefinitionsByApplicability = {
      ...chargeItemDefinitionsByApplicability,
      ...kvStore,
    }
  })

  return chargeItemDefinitionsByApplicability
}

const productToKVStore = (
  chargeItemDefinition: ChargeItemDefinition,
  billingType: GENERIC_BILLING_TYPE,
): undefined | Record<string, ChargeItemDefinition> => {
  const genericKey = getCommonCode({ codes: chargeItemDefinition.code?.coding })
  if (genericKey === "no-code") return undefined

  const baseKey = `${genericKey}|${billingType}`

  const hasApplicability = chargeItemDefinition.propertyGroup?.some((propertyGroup) => propertyGroup.applicability)

  if (!hasApplicability) return { [baseKey]: chargeItemDefinition }

  const kvStore: Record<string, ChargeItemDefinition> = {}

  chargeItemDefinition.propertyGroup?.forEach((propertyGroup) => {
    const applicabilityKey = applicabilityToKey(propertyGroup.applicability)
    if (applicabilityKey) {
      const effectiveChargeItemDefinition = {
        ...chargeItemDefinition,
        propertyGroup: [propertyGroup],
      }
      kvStore[`${baseKey}|${applicabilityKey}`] = effectiveChargeItemDefinition
    }
  })

  return kvStore
}

const applicabilityToKey = (applicability: ChargeItemDefinition["applicability"]) => {
  if (!applicability?.length) return undefined

  const applicabilityMap = new Map()
  for (const item of applicability) {
    if (item.description && item.expression) {
      applicabilityMap.set(item.description, item.expression.replaceAll(" ", ""))
    }
  }

  const result = []
  for (const { variable } of extraConfigurationData) {
    const expression = applicabilityMap.get(variable)
    if (expression) {
      result.push(expression)
    }
  }

  return result.join("|")
}

const getChargeItemDefinitionsBundle = (paramatersBundle: Parameters, billingType: GENERIC_BILLING_TYPE) => {
  return paramatersBundle?.parameter?.find((p) => p.name === billingType)?.resource as Bundle
}

const getChargeItemDefinitions = (paramatersBundle: Parameters) => {
  const chargeItemDefinitionsByGenericBillingType: Record<GENERIC_BILLING_TYPE, ChargeItemDefinition[]> = {
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: [],
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: [],
  }

  const buildToPracticeOrInsuranceBundle = getChargeItemDefinitionsBundle(
    paramatersBundle,
    GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
  )

  const buildToPatientBundle = getChargeItemDefinitionsBundle(paramatersBundle, GENERIC_BILLING_TYPE.BILL_PATIENT)

  if (buildToPracticeOrInsuranceBundle) {
    const buildToPracticeOrInsuranceChargeItemDefinitions = getResources<ChargeItemDefinition>(
      buildToPracticeOrInsuranceBundle,
      "ChargeItemDefinition",
    )

    chargeItemDefinitionsByGenericBillingType[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE].push(
      ...buildToPracticeOrInsuranceChargeItemDefinitions,
    )
  }

  if (buildToPatientBundle) {
    const buildToPatientChargeItemDefinitions = getResources<ChargeItemDefinition>(
      buildToPatientBundle,
      "ChargeItemDefinition",
    )

    chargeItemDefinitionsByGenericBillingType[GENERIC_BILLING_TYPE.BILL_PATIENT].push(
      ...buildToPatientChargeItemDefinitions,
    )
  }

  return chargeItemDefinitionsByGenericBillingType
}

const buildProductPricesParametersTemplate = (): Parameters => ({
  resourceType: "Parameters",
  parameter: [
    {
      name: "order-info",
      part: [],
    },
    {
      name: "order-info",
      part: [],
    },
    {
      name: "include-fee",
      value: {
        boolean: false,
      },
    },
  ],
})

const generateProductPricesParameters = (
  productsConfigurations: ProductConfigurationToRequest[],
  includeAllFees: boolean,
): Parameters => {
  const orderInfoParts = buildOrderInfo(productsConfigurations)
  const hasPracticeOrInsuranceBilling = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE].length > 0
  const hasPatientBilling = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PATIENT].length > 0

  const parameters: Parameters = buildProductPricesParametersTemplate()

  if (hasPracticeOrInsuranceBilling) {
    parameters.parameter![0].part = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]
  }

  if (hasPatientBilling) {
    parameters.parameter![1].part = [
      ...orderInfoParts[GENERIC_BILLING_TYPE.BILL_PATIENT],
      {
        name: "order-type",
        value: {
          string: "bill-patient",
        },
      },
    ]
  }

  if (includeAllFees) {
    parameters.parameter?.push({
      name: "include-fee",
      value: {
        boolean: true,
      },
    })
  }

  return parameters
}

type generateOrderInfoPartsOutput = {
  [GENERIC_BILLING_TYPE.BILL_PATIENT]: ParametersParameterArrayArray[]
  [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: ParametersParameterArrayArray[]
}

const buildOrderInfo = (productsConfigurations: ProductConfigurationToRequest[]): generateOrderInfoPartsOutput => {
  const orderInfo: generateOrderInfoPartsOutput = {
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: [],
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: [],
  }

  const productInfoMap: Record<string, ParametersParameterArrayArray> = {}

  for (const item of productsConfigurations) {
    const effectiveCode = getCommonCode({ codes: item.code })
    if (effectiveCode === "no-code") continue

    const billingTypes = getBillingTypes(item.billingType)
    addProductInfoToOrderInfo(item, effectiveCode, billingTypes, orderInfo, productInfoMap)
  }

  return orderInfo
}

const needsExtraConfiguration = (item: ProductConfigurationToRequest) =>
  extraConfigurationData.some(({ variable }) => Boolean(item[variable as keyof ProductConfigurationToRequest]))

const addProductInfoToOrderInfo = (
  item: ProductConfigurationToRequest,
  effectiveCode: string,
  billingTypes: GENERIC_BILLING_TYPE[],
  orderInfo: generateOrderInfoPartsOutput,
  productInfoMap: Record<string, ParametersParameterArrayArray>,
) => {
  const requiresExtraConfiguration = needsExtraConfiguration(item)
  billingTypes.forEach((billingType) => {
    const key = `${billingType}-${effectiveCode}`
    const productInfo = getOrCreateProductInfo(key, item.code, productInfoMap, requiresExtraConfiguration)

    if (productInfo.part?.[1]?.part && requiresExtraConfiguration) {
      productInfo.part[1].part.push(getProductConfig(item))
    }

    if (!productInfoMap[key]) {
      productInfoMap[key] = productInfo
      orderInfo[billingType].push(productInfo)
    }
  })
}

const getBillingTypes = (billingType: PRODUCT_CONFIGURATION_BILLING_TYPE): GENERIC_BILLING_TYPE[] => {
  return billingType === PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH
    ? [GENERIC_BILLING_TYPE.BILL_PATIENT, GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]
    : [billingType]
}

const getOrCreateProductInfo = (
  key: string,
  code: Coding,
  productInfoMap: Record<string, ParametersParameterArrayArray>,
  requiresExtraConfiguration: boolean,
): ParametersParameterArrayArray =>
  productInfoMap[key] ?? {
    name: "product-info",
    part: [
      { name: "code", value: { Coding: code } },
      ...(requiresExtraConfiguration ? [{ name: "product-configs", part: [] }] : []),
    ],
  }

const getProductConfig = ({ quantity, frequency }: Omit<ProductConfigurationToRequest, "code">) =>
  ({
    name: "config-details",
    part: [
      {
        name: "quantity",
        value: { decimal: quantity },
      },
      ...(frequency
        ? [
            {
              name: "frequency",
              value: { Quantity: frequency },
            },
          ]
        : []),
    ],
  }) as ParametersParameterArrayArray

const getGenericBillingType = (billingType: BILLING_TYPES_CODES): GENERIC_BILLING_TYPE =>
  billingType === BILLING_TYPES_CODES.BILL_PATIENT
    ? GENERIC_BILLING_TYPE.BILL_PATIENT
    : GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE

const productConfigurationToKey = (productConfiguration: ProductConfiguration) => {
  const { billingType, code, quantity, frequency } = productConfiguration

  const genericKey = code
  if (!genericKey) return NOT_FOUND_KEY

  let baseKey = `${genericKey}|${billingType}`

  if (quantity) baseKey = `${baseKey}|${quantity}`

  if (frequency) baseKey = `${baseKey}|${frequency}`

  return baseKey
}

type BuildProductConfigurationBaseArgs = {
  code: Coding | Coding[] | undefined
  quantity?: number
  frequency?: Duration
}

type BuildProductConfigurationArgs = BuildProductConfigurationBaseArgs & {
  billingType?: BILLING_TYPES_CODES
}

const buildProductConfiguration = ({
  code,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
  quantity,
  frequency,
}: BuildProductConfigurationArgs): ProductConfiguration | undefined => {
  const effectiveCode = getCommonCode({ codes: code })
  if (effectiveCode === "no-code") return undefined

  return {
    code: effectiveCode,
    billingType: getGenericBillingType(billingType),
    quantity,
    frequency: frequency ? `${frequency.value}${frequency.unit}` : undefined,
  }
}

type BuildProductConfigurationToRequestArgs = BuildProductConfigurationBaseArgs & {
  billingType: PRODUCT_CONFIGURATION_BILLING_TYPE
}

const buildProductConfigurationToRequest = ({
  code,
  billingType,
  quantity,
  frequency,
}: BuildProductConfigurationToRequestArgs): ProductConfigurationToRequest | undefined => {
  const effectiveCoding = getCommonCoding({ codes: code })
  if (!effectiveCoding) return undefined

  return {
    code: effectiveCoding,
    billingType,
    quantity,
    frequency,
  }
}

const buildBulkProductConfigurationsToRequest = ({
  codes,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
}: {
  codes: Coding[]
  billingType?: BILLING_TYPES_CODES | "both"
}): ProductConfigurationToRequest[] => {
  const result: ProductConfigurationToRequest[] = []
  const uniqueConfigurations = new Set<string>()

  for (const code of codes) {
    const productConfig = buildProductConfigurationToRequest({
      code,
      billingType:
        billingType === PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH ? billingType : getGenericBillingType(billingType),
    })

    if (productConfig) {
      const key = configurationToKey(productConfig)
      if (!uniqueConfigurations.has(key)) {
        uniqueConfigurations.add(key)
        result.push(productConfig)
      }
    }
  }

  return result
}

const NOT_FOUND_PRODUCT_KEY = "NOT_FOUND_PRODUCT"
const getProductKey = ({
  code,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
  quantity = DEFAULT_QUANTITY,
  frequency,
}: BuildProductConfigurationArgs) => {
  const productConfiguration = buildProductConfiguration({ code, billingType, quantity, frequency })
  if (!productConfiguration) return NOT_FOUND_PRODUCT_KEY

  return productConfigurationToKey(productConfiguration)
}

const getPriceByCode = ({
  productPrices,
  medCoding,
  shippingAddressState,
  quantity = 1,
  productFrequency,
  specifyMedFee = false,
  billingType = GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
}: {
  productPrices: Record<string, ChargeItemDefinition> | undefined
  medCoding?: Coding[]
  shippingAddressState?: string
  quantity?: number
  productFrequency?: Duration
  specifyMedFee?: boolean
  billingType?: GENERIC_BILLING_TYPE
}): Money | undefined => {
  const code = getCommonCoding({ codes: medCoding, shippingAddressState })

  if (!code || !productPrices) return undefined

  const productKey = getProductKey({
    code,
    billingType,
    frequency: specifyMedFee && productFrequency ? productFrequency : undefined,
    quantity,
  })

  let cid = productPrices[productKey]

  if (!cid && specifyMedFee) {
    let medFee
    for (const productId in productPrices) {
      const product = productPrices[productId]
      if (product.code?.coding?.some((c) => c.code === code.code)) {
        medFee = getFeeType([product])
        break
      }
    }

    if (medFee) {
      const updatedProductKey = getProductKey({
        code,
        billingType,
        frequency: medFee === MED_FEE_TYPE.ByFrequency ? productFrequency : undefined,
        quantity,
      })
      cid = productPrices[updatedProductKey]
    }
  }

  const cost = getBasePrice(cid?.propertyGroup?.[0]?.priceComponent)?.amount
  return cost?.value ? { value: cost.value, currency: cost?.currency ?? "USD" } : undefined
}

export {
  buildBulkProductConfigurationsToRequest,
  buildProductConfiguration,
  formatProductPricesResponse,
  generateProductPricesParameters,
  getFieldError,
  getGenericBillingType,
  getMedsProductConfigurations,
  getOrderDate,
  getPDSkuValue,
  getPriceByCode,
  getProductKey,
  SKU_SYSTEM,
}
