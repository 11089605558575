import { MedicationRequest } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { MedicationRequestInfo } from "commons"
import { getRenewedMR } from "./components/validations"

const discardMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to delete this prescription?",
    header: "Confirmation",
    acceptLabel: "Delete",
    rejectLabel: "Cancel",
    style: { width: "50vw " },
    breakpoints: { "1280px": "70vw", "1024px": "80vw", "640px": "90vw" },
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const renewMR = (medicationRequest: MedicationRequestInfo, onAcceptCliked: (mr: MedicationRequest) => void) => {
  confirmDialog({
    message: "Are you sure you want to refill this prescription?",
    header: "Confirmation",
    acceptLabel: "Refill",
    rejectLabel: "Cancel",
    style: { width: "50vw " },
    breakpoints: { "1280px": "70vw", "1024px": "80vw", "640px": "90vw" },
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => {
      onAcceptCliked(getRenewedMR(medicationRequest))
    },
  })
}

export { discardMR, renewMR }
