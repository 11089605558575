import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Appointment, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { patientAptsQueryKeys } from "../query-keys"

const useBookAppointment = (onSettled: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const book = async (appointment: Appointment) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "appointment-resource",
          resource: appointment,
        },
      ],
    }

    return operationRequest({
      endpoint: "Appointment",
      method: "POST",
      operation: "book",
      parameters,
    })
  }

  const { mutate: bookAppointment, isPending: isBooking } = useMutation({
    mutationFn: book,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientAptsQueryKeys.all })

      displayNotificationSuccess("Appointment booked successfully!")
    },
    onSettled,
  })

  return { bookAppointment, isBooking }
}

export { useBookAppointment }
