import { useInfiniteQuery } from "@tanstack/react-query"
import {
  Appointment,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
  HealthcareService,
  isHealthcareService,
  isLocation,
  isPractitioner,
  Location,
  Patient,
  Practitioner,
} from "fhir"
import { useMemo } from "react"
import { formatDate } from "date-fns/format"

import { formatsByTypes } from "data"
import { useClient } from "api"

import { patientAptsQueryKeys } from "../query-keys"
import { CalendarAppointment } from "../types"

const usePatientAppointments = ({
  patientId,
  appointmentType,
  start,
  end,
}: {
  patientId: string
  start?: Date
  end?: Date
  appointmentType?: string[]
}) => {
  const { search } = useClient()
  const queryKey = patientAptsQueryKeys.list(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _elements: "id,start,end,description,participant,appointmentType,status,comment,minutesDuration",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        status: "booked",
        _include: "actor",
        ...(appointmentType?.length
          ? { actor: appointmentType.map((type) => `HealthcareService/${type}`).join(",") }
          : {}),
      })

      start && filters.append("date", `ge${formatDate(start, formatsByTypes.ISO_8601_DATE)}`)
      end && filters.append("date", `le${formatDate(end, formatsByTypes.ISO_8601_DATE)}`)

      const bundle = await search({ endpoint: `Patient/${patientId}/Appointment`, filters, signal })

      const appointments = getResources(bundle) as Appointment[]
      const locations = getResourcesByTypeAsIndex<Location>(bundle, "Location")
      const healthcareServices = getResourcesByTypeAsIndex<HealthcareService>(bundle, "HealthcareService")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const patient = getResource<Patient>(bundle, "Patient")

      const newAppointments = appointments.map<Appointment>((appointment) => {
        return { ...appointment, ...{ start: appointment.start && new Date(appointment.start) } }
      })

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        appointments: newAppointments,
        practitioners,
        patient,
        locations,
        healthcareServices,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { appointments, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.appointments)
    const healthcareServices = data?.pages.reduce(
      (acc, page) => ({
        ...acc,
        ...page.healthcareServices,
      }),
      {} as Record<string, HealthcareService>,
    )
    const locations = data?.pages.reduce((acc, page) => ({ ...acc, ...page.locations }), {} as Record<string, Location>)
    const practitioners = data?.pages.reduce(
      (acc, page) => ({ ...acc, ...page.practitioners }),
      {} as Record<string, Practitioner>,
    )

    const currentPatient = data?.pages?.[0]?.patient

    const count = newData?.length

    const newAppointments =
      newData?.map<CalendarAppointment>((appointment) => {
        const { practitioner, patient, location, healthcareService } =
          appointment.participant?.reduce<{
            practitioner?: Practitioner
            patient?: Patient
            location?: Location
            healthcareService?: HealthcareService
          }>(
            (prev, { actor }) => {
              if (isPractitioner(actor) && actor.id) return { ...prev, practitioner: practitioners?.[actor.id] }
              if (isLocation(actor) && actor.id) return { ...prev, location: locations?.[actor.id as string] }
              if (isHealthcareService(actor) && actor.id)
                return { ...prev, healthcareService: healthcareServices?.[actor.id] }
              return prev
            },
            { patient: currentPatient },
          ) ?? {}

        return {
          appointment,
          practitioner,
          patient,
          location,
          healthcareService,
        }
      }) ?? []

    return {
      appointments: newAppointments,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    appointments,
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

export { usePatientAppointments }
