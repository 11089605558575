import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { coverageKeys } from "../query-keys"

const useCancelCoverage = (hideForm: () => void, patientId: string) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (coverageId: string) =>
    operationRequest({ endpoint: "Coverage", method: "POST", operation: "cancel-coverage", id: coverageId })

  const { mutate: cancelCoverage, isPending: isCanceling } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { coverageId: id }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: coverageKeys.withPatientId(patientId), refetchType: "all" })

      displayNotificationSuccess("Insurance cancelled successfully!")
    },
    onSettled: hideForm,
  })

  return { cancelCoverage, isCanceling }
}

export { useCancelCoverage }
