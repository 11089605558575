import { ChargeItemDefinition } from "fhir"
import { FC, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHexagonExclamation } from "@fortawesome/pro-regular-svg-icons"

import { SkeletonLoader } from "commons"

import { MedShippingCondition, OrderShippingItem } from "./OrderShippingItem"
import { ACTION_GROUP_CODES, ActionGroupCode } from "../../types"
import { useCPOEContext } from "../../hooks"

const OrdersShippingMethod: FC<Props> = ({
  nutraShippingMethods,
  medsShippingMethods,
  selectedShippingMethods,
  isLoading,
  onChangeShippingMethod,
  ...shippingCondition
}) => {
  const shippingSelectedForType = (groupId: ActionGroupCode) => {
    switch (groupId) {
      case ACTION_GROUP_CODES.PHARMA:
        return (
          !!medsShippingMethods?.length &&
          medsShippingMethods.some((cid) => selectedShippingMethods?.some((selected) => selected.id === cid.id))
        )

      case ACTION_GROUP_CODES.NUTRA:
        return (
          !!nutraShippingMethods?.length &&
          nutraShippingMethods.some((cid) => selectedShippingMethods?.some((selected) => selected.id === cid.id))
        )

      default:
        return false
    }
  }

  return (
    <div id="shipping-method-section" className="mt-10 border-t border-gray-200 pt-10">
      <h2 className="text-lg font-medium text-gray-900">
        {!nutraShippingMethods?.length ? "Additional actions" : "Shipping method"}
      </h2>
      {isLoading ? (
        <div className="grid grid-flow-col gap-2 w-full mt-5">
          <SkeletonLoader loaderType="min-card" skeletonSize="5.5rem" repeats={3} />
        </div>
      ) : (
        <div className="flex flex-col space-y-5">
          {!!nutraShippingMethods?.length && (
            <>
              <p>For Nutraceuticals</p>
              {!shippingSelectedForType(ACTION_GROUP_CODES.NUTRA) && <ShippingError key={ACTION_GROUP_CODES.NUTRA} />}
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
                {nutraShippingMethods.map((item, index) => (
                  <OrderShippingItem
                    key={item.id ?? index}
                    shipping={item}
                    selectShipping={() => onChangeShippingMethod(item, ACTION_GROUP_CODES.NUTRA)}
                    selected={selectedShippingMethods?.some(({ id }) => id === item.id) ?? false}
                    {...shippingCondition}
                  />
                ))}
              </div>
            </>
          )}
          {!!medsShippingMethods?.length && (
            <>
              <p>For Pharmaceuticals</p>
              {!shippingSelectedForType(ACTION_GROUP_CODES.PHARMA) && <ShippingError key={ACTION_GROUP_CODES.PHARMA} />}
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
                {medsShippingMethods.map((item, index) => (
                  <OrderShippingItem
                    key={item.id ?? index}
                    shipping={item}
                    selectShipping={() => onChangeShippingMethod(item, ACTION_GROUP_CODES.PHARMA)}
                    selected={selectedShippingMethods?.some(({ id }) => id === item.id) ?? false}
                    {...shippingCondition}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const ShippingError: FC = () => {
  const { checkoutState, setCheckoutState } = useCPOEContext()

  useEffect(() => {
    if (!checkoutState.loading) setCheckoutState({ ...checkoutState, disabled: true })
  }, [checkoutState.loading])

  return (
    <small className="p-error mt-1">
      <FontAwesomeIcon icon={faHexagonExclamation} className="mr-1" />
      Please specify a shipping method to proceed
    </small>
  )
}

type Props = MedShippingCondition & {
  nutraShippingMethods?: ChargeItemDefinition[]
  medsShippingMethods?: ChargeItemDefinition[]
  selectedShippingMethods?: ChargeItemDefinition[]
  isLoading: boolean
  onChangeShippingMethod(shippingMethod: ChargeItemDefinition, groupId: ActionGroupCode): void
  isSelectionDisabled?: boolean
}

export { OrdersShippingMethod }
