import { useMutation } from "@tanstack/react-query"
import { asReference, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { useSidebarContext } from "./useSidebarContext"

const useCheckEmailExists = (onSuccess?: (data: boolean) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const { organization } = useSidebarContext()

  const checkEmail = async ({
    emailToCheck,
    isPractitionerRole,
  }: {
    emailToCheck?: string
    isPractitionerRole?: boolean
  }) => {
    if (!emailToCheck) return false

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "email",
          value: {
            string: emailToCheck,
          },
        },
        { name: "resourceType", value: { string: isPractitionerRole ? "Practitioner" : "Patient" } },
        ...(!isPractitionerRole ? [{ name: "organization", value: { Reference: asReference(organization) } }] : []),
      ],
    }

    const results = await operationRequest<Parameters>({
      endpoint: "",
      method: "POST",
      operation: "email-exists-v2",
      parameters,
    })
    return results.parameter?.find((param) => param.name === "exist")?.value?.boolean ?? false
  }

  const {
    mutateAsync: checkEmailExists,
    isPending,
    data,
    isIdle,
  } = useMutation({
    mutationKey: ["check-email-exists"],
    mutationFn: checkEmail,
    onError: (error: CustomError, email) => {
      displayNotificationError(registerErrorTrace(error, { email }))
    },
    onSuccess: (data) => {
      onSuccess?.(data)
    },
    onSettled,
  })

  return { checkEmailExists, isCheckingEmail: isPending, exists: data ?? false, hasBeenChecked: !isIdle }
}

export { useCheckEmailExists }
