import { faLayerGroup, faPlus, faSquareArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { faCalendarDays, faSdCards, faFiles, faUser } from "@fortawesome/pro-regular-svg-icons"
import { DocumentReference, asReference } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InfiniteScroll from "react-infinite-scroller"
import { useId } from "react"

import {
  Button,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useAzureContainer,
  useCrudReducer,
} from "commons"
import { useLoginContext } from "security"
import { bytesToMegaBytes, formatDate } from "utils"
import { ProfileSection, azureContainer, formatsByTypes } from "data"

import { usePatientDocuments, useShowPatientDocument } from "../hooks"
import { documentInitialValues } from "./validations"
import { PatientDocumentForm } from "./PatientDocumentForm"

const PatientDocuments = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { showSlide, initialValue, add, reset } = useCrudReducer({
    defaultEntity: documentInitialValues(asReference(loggedInPatient)),
  })

  const { documents, fetchNextPage, hasNextPage, isLoading, refetchDocs } = usePatientDocuments(loggedInPatientId)
  const { showDocument, isLoading: isLoadingDocument } = useShowPatientDocument()

  const { uploadFile } = useAzureContainer(azureContainer.docs, () => refetchDocs(), reset)

  const handleDownload = (url: string) => {
    showDocument({ url })
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <InformationCardContainer
      id={ProfileSection.PATIENT_DOCUMENTS}
      title="Documents"
      showEdit={!!documents?.length}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
      className="profile-card-section"
    >
      {isLoading ? (
        loader()
      ) : (
        <DataContainerSlideoverForm
          hasData={!!documents?.length}
          showSlide={showSlide}
          formTitle="Documents"
          iconDataNotFound={faFiles}
          formInitialValue={initialValue}
          onSubmit={(data) => {
            uploadFile({ file: data.attachment as File, subject: data.subject, category: data.category })
          }}
          onCancel={reset}
          form={<PatientDocumentForm />}
          showAddButton={false}
          onButtonAddClick={add}
        >
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <StackedListContainer
              data={documents ?? []}
              itemModelBuilder={(item) => documentModelBuilder(item, handleDownload, isLoadingDocument)}
            />
          </InfiniteScroll>
        </DataContainerSlideoverForm>
      )}
    </InformationCardContainer>
  )
}

const documentModelBuilder = (
  document: DocumentReference,
  download: (url: string) => void,
  isLoading: boolean,
): StackedListItemProps => {
  const attachment = document.content[0].attachment
  const category = document.category?.[0].coding?.[0]

  return {
    leftData: [
      { lineItems: [{ name: "Title", value: attachment.title ?? "Unspecified" }] },
      {
        lineItems: [
          ...(attachment.creation
            ? [
                {
                  name: "Recorded date",
                  value: formatDate(new Date(attachment.creation), formatsByTypes.LONG_DATETIME),
                  icon: faCalendarDays,
                },
              ]
            : []),
          ...(attachment.size
            ? [
                {
                  name: "Storage size",
                  value: `${bytesToMegaBytes(attachment.size)}MB`,
                  icon: faSdCards,
                },
              ]
            : []),
          ...(document.author?.[0]
            ? [
                {
                  name: "Author",
                  value: document.author[0].display ?? "Unknown",
                  icon: faUser,
                },
              ]
            : []),
          ...(category
            ? [
                {
                  name: "Author",
                  value: category.display ?? "Unknown",
                  icon: faLayerGroup,
                },
              ]
            : []),
        ],
      },
    ],
    menu: [
      ...(attachment.url
        ? [
            {
              label: "Download",
              icon: <FontAwesomeIcon icon={faSquareArrowDown} size="sm" className="mr-2" />,
              command: () => {
                download(document.content[0].attachment.url as string)
              },
            },
          ]
        : []),
    ],
    isLoading,
  }
}

export { PatientDocuments }
