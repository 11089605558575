import { EventInput } from "@fullcalendar/core"
import { isSameDay, isSameMonth } from "date-fns"
import { Badge } from "primereact/badge"
import { Calendar, CalendarDateTemplateEvent, CalendarMonthChangeEvent } from "primereact/calendar"
import { Tooltip } from "primereact/tooltip"
import { FormEvent } from "primereact/ts-helpers"
import { SyntheticEvent, useEffect, useRef, useState } from "react"

const AppointmentCalendar = ({ currentDate, selectDate, currentDateAppointments, onMonthChange }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(currentDate ?? new Date())
  const prevMonthStartDate = useRef<Date>(new Date())

  const handleChange = (e: FormEvent<Date, SyntheticEvent<Element, Event>>) => {
    if (currentDate && isSameMonth(currentDate, e.value as Date)) {
      setSelectedDate(e.value as Date)
      selectDate(e.value as Date)
    }
  }

  useEffect(() => {
    if (!!currentDate && !isSameDay(prevMonthStartDate.current, currentDate)) {
      setSelectedDate(currentDate)
      prevMonthStartDate.current = currentDate
    }
  }, [currentDate])

  const dateTemplate = (date: CalendarDateTemplateEvent) => {
    const dateStr = `${date?.year}-${date?.month + 1}-${date?.day}`
    const appointments = currentDateAppointments(new Date(date?.year, date?.month, date?.day))
    const count = appointments.length

    return count > 0 ? (
      <>
        <span className={`w-full h-full grid content-center justify-center date_${dateStr}`}>
          {date?.day}
          <Tooltip
            target={`.date_${dateStr}`}
            content={`${count} ${count !== 1 ? "appointments" : "appointment"}`}
            position="top"
            event="hover"
            className="text-xs p-0 m-0"
          />
        </span>
        <Badge severity="info" className={`absolute right-0.5 top-0.5 date_${dateStr}`} />
      </>
    ) : (
      <>{date?.day}</>
    )
  }

  return (
    <Calendar
      value={selectedDate}
      className="custom-appointment-calendar grow"
      panelClassName="border-none border-0"
      onChange={handleChange}
      inline
      dateTemplate={dateTemplate}
      onMonthChange={onMonthChange}
    />
  )
}

type Props = {
  currentDate?: Date
  selectDate(date: Date): void
  currentDateAppointments(date: Date): Array<EventInput>
  onMonthChange?(_: CalendarMonthChangeEvent): void
}
export { AppointmentCalendar }
