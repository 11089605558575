import { createContext, FC, PropsWithChildren, useEffect } from "react"
import { Location, Organization, SettingItemArray } from "fhir"

import { useCountMedicationRequests } from "medication-requests"
import { useCountInvoices } from "invoices"
import { ThemeDefinition, useBranding, useModulesVisibility, usePurchaseSetting } from "settings"
import { useCountNotifications } from "notifications"
import { useOrganization } from "organization"
import { useLoginContext } from "security"
import { useCheckNewReleaseAvailable } from "commons/hooks"

const SidebarCtx = createContext<State | undefined>(undefined)

const SidebarContextProvider: FC<PropsWithChildren<Props>> = ({ isSmallScreen, children }) => {
  const { managingOrganizationId, loggedInPatientId } = useLoginContext()
  const { medicationRequestsCount, reloadMedications } = useCountMedicationRequests(loggedInPatientId, ["active"])
  const { issuedInvoicesCount, reloadInvoicesCount } = useCountInvoices(loggedInPatientId, ["issued"])
  const { branding } = useBranding(managingOrganizationId)
  const { modulesSettings } = useModulesVisibility(managingOrganizationId)
  const { purchaseEnabled, activeCatalogs } = usePurchaseSetting(managingOrganizationId)
  const { notificationCount } = useCountNotifications(loggedInPatientId)
  const { organization, location } = useOrganization(managingOrganizationId)

  const hslHoverValues = (hslColor: string) => {
    const values = hslColor
      .replace("%", "")
      .split(" ")
      .map((v) => parseInt(v))
    return [values[0], values[1] - 7, values[2] > 20 ? values[2] - 7 : values[2] - 3]
  }

  const applyBrandingColor = (colorName: string, brandingColor?: string) => {
    if (brandingColor && new RegExp(/^\d+deg \d+% \d+%$/g).test(brandingColor)) {
      const hoverHsl = hslHoverValues(brandingColor)
      document.body.style.setProperty(`--color-${colorName}`, brandingColor as string)
      document.body.style.setProperty(`--color-${colorName}-hover`, `${hoverHsl[0]}deg ${hoverHsl[1]}% ${hoverHsl[2]}%`)
    }
  }

  useCheckNewReleaseAvailable()

  applyBrandingColor("primary", branding.primaryColor)
  applyBrandingColor("secondary", branding.secondaryColor)

  if (organization.name) document.title = `${organization.name} - Patient Portal`

  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
    if (link) link.href = branding.iconLogo as string
  }, [branding.iconLogo])

  const isModuleActive = (module: string) => modulesSettings?.[`${module}-enabled`]?.value?.boolean === true

  const isStripeSetupComplete =
    organization.identifier?.some(
      (identifier) => identifier.system === "http://chartedhealth.com/fhir/payment/stripe",
    ) ?? false

  return (
    <SidebarCtx.Provider
      value={{
        organizationId: organization.id as string,
        organization,
        medicationRequestsCount,
        reloadMedications,
        issuedInvoicesCount,
        reloadInvoicesCount,
        branding,
        modulesSettings,
        isModuleActive,
        notificationCount,
        purchaseEnabled,
        isStripeSetupComplete,
        activeCatalogs,
        isSmallScreen,
        location,
      }}
    >
      {children}
    </SidebarCtx.Provider>
  )
}

type Props = {
  isSmallScreen: boolean
}

type State = Props & {
  organizationId: string
  organization: Organization
  medicationRequestsCount: number
  issuedInvoicesCount: number
  branding: ThemeDefinition & { ehrUrl: string | undefined }
  modulesSettings?: Record<string, SettingItemArray>
  reloadMedications(): void
  reloadInvoicesCount(): void
  isModuleActive(module: string): boolean
  notificationCount: number
  purchaseEnabled: boolean
  isStripeSetupComplete: boolean
  activeCatalogs: string[]
  location?: Location
}

export { SidebarContextProvider, SidebarCtx }
