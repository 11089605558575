import { Appointment, asReference } from "fhir"
import { FC } from "react"

import { DialogFormContainer, SlideFormContainer, useSidebarContext } from "commons"
import { useLoginContext } from "security"

import { AppointmentForm } from "./AppointmentForm"
import { appointmentValidationSchema, initialValues } from "./validations"

const AppointmentFormOverlay: FC<Props> = ({ appointment, onSubmit, onHide, visible, isEditing }: Props) => {
  const { isSmallScreen, location, organizationId } = useSidebarContext()
  const formTitle = `${isEditing ? "Update" : "Schedule"} Appointment`

  const { loggedInPatient } = useLoginContext()

  if (isSmallScreen)
    return (
      <SlideFormContainer
        showSlide={!!visible}
        title={formTitle}
        onCancel={onHide}
        onSubmit={onSubmit}
        initialValue={initialValues({ appointment, patientRef: asReference(loggedInPatient), location })}
        validationSchema={appointmentValidationSchema}
        saveLabel={isEditing ? "Update" : "Schedule"}
      >
        <AppointmentForm organizationId={organizationId} />
      </SlideFormContainer>
    )

  return (
    <DialogFormContainer
      title={formTitle}
      showForm={!!visible}
      initialValue={initialValues({ appointment, patientRef: asReference(loggedInPatient), location })}
      useFormik
      validationSchema={appointmentValidationSchema}
      onSubmit={onSubmit}
      onCancel={onHide}
      enableReinitialize
      className="w-[95%] lg:w-4/5 2xl:w-max @container"
      saveLabel={isEditing ? "Update" : "Schedule"}
    >
      <AppointmentForm organizationId={organizationId} />
    </DialogFormContainer>
  )
}

type Props = {
  visible?: boolean
  isEditing?: boolean
  appointment: Appointment
  onHide(): void
  onSubmit(appointment: Appointment): void
}

export { AppointmentFormOverlay }
