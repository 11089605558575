import {
  Dosage,
  Duration,
  MedicationKnowledge,
  Quantity,
  Reference,
  MedicationRequest,
  asReference,
  PractitionerRole,
  MedicationRequestDispenseRequest,
} from "fhir"
import { add, format } from "date-fns"

import { formatsByTypes, mrCategoryCodes, unitOfTime } from "data"
import { MedicationRequestInfo } from "commons"
import { isRefrigeratedMedicationKnowledge, medicationKnowledgeRegulations } from "utils"

const DEFAULT_TREATMENT_DURATION = {
  value: 1,
  system: unitOfTime[5].system,
  code: unitOfTime[5].code,
  unit: unitOfTime[5].display.toLowerCase(),
}

const getInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  patientRef: Reference,
  catalogAuthor: Reference,
  duration: Duration,
  patientGeneralPractRole: PractitionerRole,
): MedicationRequest => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0].dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: "http://chartedhealth.com/fhir/ValueSet/medicationknowledge-package-type",
  } as Quantity
  const currentDate = format(new Date(), formatsByTypes.ISO_8601_DATETIME)

  const mrCategory = [{ coding: [mrCategoryCodes.nutraceutical], text: mrCategoryCodes.nutraceutical.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)
  const regulations = medicationKnowledgeRegulations(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({ coding: [mrCategoryCodes.refrigerated], text: mrCategoryCodes.refrigerated.display })
  }

  if (regulations?.length) {
    mrCategory.push(
      ...regulations.map((code) => ({
        coding: [code],
        text: code.display ?? code.code,
      })),
    )
  }

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    authoredOn: currentDate,
    subject: patientRef,
    requester: asReference(patientGeneralPractRole),
    performer: patientRef,
    recorder: patientRef,
    dosageInstruction: mkDefaultDosages,
    dispenseRequest: {
      performer: catalogAuthor,
      initialFill: {
        quantity: defaultQuantity,
        duration: (duration.value as number) > 0 ? duration : DEFAULT_TREATMENT_DURATION,
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: duration,
      expectedSupplyDuration: (duration.value as number) > 0 ? duration : DEFAULT_TREATMENT_DURATION,
      quantity: defaultQuantity,
    },
    category: mrCategory,
  }
}

const sanitize = (medicationReq: MedicationRequestInfo) => {
  const currentDate = new Date().toISOString()

  const quantity = medicationReq.dispenseRequest?.initialFill?.quantity as Quantity
  const duration = medicationReq.dispenseRequest?.initialFill?.duration as Duration

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    quantity,
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: currentDate,
      ...((medicationReq.dispenseRequest?.dispenseInterval?.value ?? 0) > 0
        ? {}
        : {
            end: add(
              medicationReq.dispenseRequest?.validityPeriod?.start
                ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
                : new Date(),
              { [`${duration.unit ?? "second"}s`]: duration.value ?? 0 },
            ).toISOString(),
          }),
    },
  }

  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  delete medicationReq.medicationUnit

  return medicationReq
}

const getRenewedMR = ({ ...medicationRequest }: MedicationRequestInfo) => {
  medicationRequest.priorPrescription = asReference(medicationRequest)
  const newDate = new Date().toISOString()
  medicationRequest.authoredOn = newDate
  medicationRequest.status = "draft"

  const duration = medicationRequest.dispenseRequest?.initialFill?.duration
  const interval = medicationRequest.dispenseRequest?.dispenseInterval?.value

  medicationRequest.dispenseRequest = {
    ...medicationRequest.dispenseRequest,
    nextRefillDate: newDate,
    validityPeriod: {
      start: newDate,
      ...(!interval
        ? {
            end: add(new Date(newDate), {
              [`${duration?.unit ?? "second"}s`]:
                (duration?.value ?? 0) * ((medicationRequest.dispenseRequest?.numberOfRepeatsAllowed ?? 0) + 1),
            }).toISOString(),
          }
        : {}),
    },
  }

  delete medicationRequest.id
  delete medicationRequest.unitsByRecipient
  delete medicationRequest.medicationUnit
  delete medicationRequest.treatmentFrequency
  delete medicationRequest.doseQuantity
  delete medicationRequest.requesterRef

  return medicationRequest
}

const sanitizeDispenseRequest = (mrDispenseRequest: MedicationRequestDispenseRequest) => {
  const quantity = mrDispenseRequest?.initialFill?.quantity as Quantity
  const duration = mrDispenseRequest?.initialFill?.duration as Duration
  const interval = mrDispenseRequest.dispenseInterval?.value

  const repeats = mrDispenseRequest?.numberOfRepeatsAllowed

  mrDispenseRequest = {
    ...mrDispenseRequest,
    quantity,
    ...(repeats !== undefined ? { numberOfRepeatsAllowed: repeats } : undefined),
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: mrDispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      ...(!interval
        ? {
            end: add(
              mrDispenseRequest?.validityPeriod?.start ? new Date(mrDispenseRequest.validityPeriod.start) : new Date(),
              { [`${duration.unit ?? "second"}s`]: (duration.value ?? 0) * ((repeats ?? 0) + 1) },
            ).toISOString(),
          }
        : {}),
    },
  }
  return mrDispenseRequest
}

export { getInitialValues, sanitize, getRenewedMR, sanitizeDispenseRequest }
