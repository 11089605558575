import { faCreditCard, faHexagonExclamation } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Account, AccountBETACreditCardArray } from "fhir"
import { useEffect, useMemo } from "react"

import {
  CreditCardForm,
  CreditCardFormData,
  creditCardValidationSchema,
  getInitialValues,
  useCreateCreditCard,
  useCreditCards,
  useUpdateDefaultCreditCard,
} from "account"
import { AddFieldArrayItemButton, DialogFormContainer, SkeletonLoader, useCrudReducer } from "commons"
import { SmartyAddressVerificationProvider } from "commons/context"
import { useLoginContext } from "security"
import { getCcId } from "utils"

import { OrderCreditCardListItem } from "./OrderCreditCardListItem"

const OrderCreditCardContainer = ({ defaultCreditCard, isLoading }: Props) => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { showSlide, selectedItem, add, selectItem, reset } = useCrudReducer({ defaultEntity: defaultCreditCard })

  const { creditCards, isLoading: isLoadingCC, account } = useCreditCards(loggedInPatientId)
  const { updateDefaultCreditCard, isUpdating } = useUpdateDefaultCreditCard()
  const { createCreditCard, isAdding } = useCreateCreditCard(reset)

  useEffect(() => {
    if (!selectedItem && !isLoadingCC && defaultCreditCard) selectItem(defaultCreditCard)
  }, [isLoadingCC, defaultCreditCard, selectItem, selectedItem])

  const ccOptions = useMemo(() => creditCards.map((cc) => ({ ...cc, id: getCcId(cc) })), [creditCards])

  const onSelect = (cc: AccountBETACreditCardArray) => {
    selectItem(cc)
    updateDefaultCreditCard({ creditCardId: getCcId(cc), account: account as Account })
  }

  const onSubmit = (creditCard: CreditCardFormData) => {
    createCreditCard({
      creditCard,
      account: account as Account,
      creditCardList: creditCards,
    })
  }

  return (
    <div
      id="payment-method-section"
      className="first:mt-0 first:pt-0 first:border-none mt-10 border-t border-gray-200 pt-10"
    >
      <h3 className="text-lg font-medium text-gray-900">Payment Method</h3>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
        {isLoadingCC || isAdding ? (
          <SkeletonLoader loaderType="min-card" skeletonSize="3rem" repeats={2} />
        ) : ccOptions.length ? (
          ccOptions.map((cc) => (
            <OrderCreditCardListItem
              key={cc.id}
              creditCard={cc}
              selected={!!selectedItem && cc.id === getCcId(selectedItem)}
              onSelect={onSelect}
              disabled={isLoading || isLoadingCC || isAdding || isUpdating}
            />
          ))
        ) : (
          <div>
            <p className="col-span-full text-gray-500 text-sm">
              <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
              No payment methods added yet
            </p>
            {!defaultCreditCard && (
              <small className="p-error">
                <FontAwesomeIcon icon={faHexagonExclamation} className="mr-1" />
                Please provide one payment method
              </small>
            )}
          </div>
        )}
      </div>
      <AddFieldArrayItemButton onClick={add} disabled={isLoading || isLoadingCC || isAdding} />
      <DialogFormContainer
        showForm={showSlide}
        title="Credit card"
        initialValue={getInitialValues(loggedInPatient)}
        validationSchema={creditCardValidationSchema}
        onSubmit={onSubmit}
        onCancel={reset}
      >
        <SmartyAddressVerificationProvider>
          <CreditCardForm isEditing={false} />
        </SmartyAddressVerificationProvider>
      </DialogFormContainer>
    </div>
  )
}

type Props = {
  defaultCreditCard?: AccountBETACreditCardArray
  isLoading: boolean
}

export { OrderCreditCardContainer }
