import { useInfiniteQuery } from "@tanstack/react-query"
import { type Invoice, type PaymentReconciliation, Task, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { invoicesKeys } from "../query-keys"

const useInvoices = ({ patientId, statusFilter = [], searchText, includeTasks }: InvoicesArgs) => {
  const { search } = useClient()

  const queryKey = invoicesKeys.withStatusAndSearchFilter(patientId, statusFilter, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    InvoicesQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ signal, pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { "identifier:text": searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        ...(includeTasks ? { _revinclude: "Task:focus" } : {}),
        _revinclude: "PaymentReconciliation:r5-request:Invoice",
        recipient: patientId,
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Invoice`, filters, signal })
      const invoices = getResources(bundle, "Invoice") as Invoice[]
      const paymentReconciliations = getResources(bundle, "PaymentReconciliation") as PaymentReconciliation[]
      const tasks = getResources<Task>(bundle, "Task")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { invoices, paymentReconciliations, tasks, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })
  const { invoiceData, count, dependsTasks, invoices } = useMemo(() => {
    if (!data?.pages) {
      return { invoiceData: [], count: 0 }
    }

    const tasks = data?.pages.flatMap((page) => page.tasks)

    const newInvoices = data.pages.flatMap((page) => page.invoices)
    const newPaymentReconciliations = data.pages.flatMap((page) => page.paymentReconciliations)

    const payRecsByInvoiceId = new Map()
    newPaymentReconciliations.forEach((payRec) => {
      const invoiceId = payRec.R5_request?.id
      if (invoiceId) {
        const existing = payRecsByInvoiceId.get(invoiceId) || []
        existing.push(payRec)
        payRecsByInvoiceId.set(invoiceId, existing)
      }
    })
    const dependsTasks =
      tasks?.reduce<Record<string, Task>>((acc, task) => {
        if (
          ["complete-shipping-address", "complete-shipping-method", "complete-cc"].includes(
            task.code?.coding?.[0].code ?? "",
          )
        )
          return { ...acc, [task.focus?.id as string]: task }

        return acc
      }, {}) ?? {}

    const invoiceData = newInvoices.map((invoice) => ({
      invoice,
      payRecs: (payRecsByInvoiceId.get(invoice.id) || []) as PaymentReconciliation[],
    }))

    return {
      invoiceData,
      dependsTasks,
      invoices: newInvoices,
      count: newInvoices.length,
    }
  }, [data?.pages])

  return {
    invoiceData,
    invoices,
    dependsTasks,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadInvoices: refetch,
  }
}

interface InvoicesArgs {
  patientId: string
  statusFilter?: string[]
  searchText?: string
  includeTasks?: boolean
}

type InvoicesQueryData = {
  invoices: Invoice[]
  paymentReconciliations: PaymentReconciliation[]
  tasks: Task[]
  next: number | undefined
  total: number
}

export { useInvoices }
