import { Organization, Patient, Reference } from "fhir"
import { createContext, FC, PropsWithChildren, ReactNode, useMemo } from "react"

import { useLoggedUserPatients } from "security"
import { CustomError, LoadingView } from "commons"
import { usePatientOrganizations } from "organization"
import { registerErrorTrace } from "logger"

const HomeContext = createContext<State | undefined>(undefined)
HomeContext.displayName = "HomeContext"

const HomeContextProvider: FC<Props> = ({ children }: PropsWithChildren<Props>) => {
  const { patientRefs, patientsIndexed, isLoading } = useLoggedUserPatients()
  const { patientOrganizations, isLoading: isLoadingPatientOrganizations } = usePatientOrganizations(patientsIndexed)

  const cleanedOptions = useMemo(
    () =>
      patientRefs.reduce((prev, ref) => {
        const org = patientOrganizations?.[ref.id ?? ""]

        if (org?.id) {
          return [...prev, { ...ref, display: org.name ?? "unspecified name" }]
        }

        return prev
      }, [] as Reference[]),
    [patientRefs, patientOrganizations],
  )

  if (isLoading || isLoadingPatientOrganizations) {
    return <LoadingView />
  }

  if (!patientRefs) {
    throw registerErrorTrace(
      new Error("Forbidden", {
        cause: {
          name: "403",
          message: "Forbidden Access",
          logoutFlag: true,
        },
      }) as CustomError,
    )
  }

  return (
    <HomeContext.Provider
      value={{
        patientRefs: cleanedOptions,
        patientsIndexed: patientsIndexed,
        patientOrganizations: patientOrganizations,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

type State = {
  patientRefs: Reference[]
  patientsIndexed: Record<string, Patient>
  patientOrganizations: Record<string, Organization> | undefined
}

type Props = {
  children: ReactNode
}

export { HomeContextProvider, HomeContext }
