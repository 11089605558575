import { useQuery } from "@tanstack/react-query"
import { formatDate } from "date-fns"
import { Bundle, Parameters, Reference, Slot, getResources } from "fhir"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { patientAptsQueryKeys } from "../query-keys"

const useFindAvailableSlots = ({
  healthcareService,
  patient,
  practitioner,
  startDate,
  endDate,
}: {
  healthcareService?: Reference
  patient?: Reference
  practitioner?: Reference
  startDate?: Date
  endDate?: Date
}) => {
  const { operationRequest } = useClient()
  const queryKey = patientAptsQueryKeys.availableSlots(patient?.id, healthcareService?.id, startDate, endDate)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const start = formatDate(startDate!, formatsByTypes.ISO_8601_DATETIME)
      const end = formatDate(endDate!, formatsByTypes.ISO_8601_DATETIME)

      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "start",
            value: {
              dateTime: start,
            },
          },
          {
            name: "end",
            value: {
              dateTime: end,
            },
          },
          {
            name: "appointment-type",
            value: {
              Reference: healthcareService,
            },
          },
          {
            name: "patient",
            value: {
              Reference: patient,
            },
          },
          {
            name: "practitioner",
            value: {
              Reference: practitioner,
            },
          },
        ],
      }

      const bundle = await operationRequest<Bundle>({
        endpoint: "Appointment",
        method: "POST",
        operation: "find",
        parameters,
      })

      const slots = getResources<Slot>(bundle, "Slot")

      return { slots }
    },
    enabled: !!healthcareService?.id && !!startDate && !!endDate && !!patient?.id && !!practitioner?.id,
    meta: { context: { queryKey } },
  })

  return { slots: data?.slots ?? [], isLoading }
}

export { useFindAvailableSlots }
