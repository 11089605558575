import { ProductConfigurationToRequest } from "./types"

//uses the names of the functions there are been use to keep it `commons`
const commonsQueryKeys = {
  productPrices: (organizationId: string, productsConfigurations: ProductConfigurationToRequest[]) => [
    "organization/product-prices",
    organizationId,
    productsConfigurations,
  ],
  signed: ["signedUrl"] as const,
  signedWithUrl: (url: string) => [...commonsQueryKeys.signed, url],
  loggedIn: (userId?: string) => ["logged-in-patient", ...(userId ? [userId] : [])] as const,
}

export { commonsQueryKeys }
