import { format } from "date-fns"
import { MedicationDispense, codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { classNames } from "primereact/utils"

import { formatsByTypes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getStringAddress } from "utils"
import { MedicationRequestInfo } from "commons"

import { dispenseInterval } from "../data"

const MedicationRequestDetails = ({
  medicationRequest,
  medicationDispense,
  containerClassName,
  titleClassName = "font-medium",
  textClassName = "mb-3 p-1 text-slate-400",
  showAsList,
}: Props) => {
  const { dispenseRequest, note, dosageInstruction, requesterRef } = medicationRequest ?? {}
  const lastMedicationDispense = medicationDispense?.at(-1)
  const pharmacyStatus =
    lastMedicationDispense?.statusReason?.CodeableConcept &&
    codeableConceptAsString(lastMedicationDispense?.statusReason?.CodeableConcept)
  const trackingCode = lastMedicationDispense?.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.MEDICATION_DISPENSE_TRACKING_CODE,
  )?.value

  const NOTES_LABEL = "Notes"

  const dispenseValue = `${dispenseRequest?.dispenseInterval?.value} ${dispenseRequest?.dispenseInterval?.unit}`
  const dispenseOption = dispenseInterval.find(({ value: { value, unit } }) => `${value} ${unit}` === dispenseValue)

  const mrData = [
    ...(dispenseRequest?.quantity?.value
      ? [
          {
            label: "Quantity",
            value: `${dispenseRequest?.quantity?.value} ${dispenseRequest?.quantity?.unit ?? pluralize("unit", dispenseRequest?.quantity?.value)}`,
          },
        ]
      : []),
    ...(dispenseOption
      ? [
          {
            label: "Dispense",
            value: dispenseOption.label,
          },
        ]
      : []),
    {
      label: "Refills",
      value: `${dispenseRequest?.numberOfRepeatsAllowed ?? 0} ${pluralize("refill", dispenseRequest?.numberOfRepeatsAllowed ?? 0)}`,
    },
    ...(dispenseRequest?.nextRefillDate && (dispenseRequest?.numberOfRepeatsAllowed ?? 0) > 0
      ? [
          {
            label: "Next Refill",
            value: `after ${format(new Date(dispenseRequest?.nextRefillDate), formatsByTypes.LONG_DATE)}`,
          },
        ]
      : []),
    ...(requesterRef?.display
      ? [
          {
            label: "Prescriber",
            value: requesterRef.display,
          },
        ]
      : []),
    ...(dispenseRequest?.performer?.display
      ? [
          {
            label: "Pharmacy",
            value: dispenseRequest?.performer?.display,
          },
        ]
      : []),
    ...(pharmacyStatus
      ? [
          {
            label: "Pharmacy Status",
            value: pharmacyStatus,
          },
        ]
      : []),
    ...(pharmacyStatus === "Shipped" && trackingCode
      ? [
          {
            label: "Tracking Number",
            value: trackingCode,
          },
        ]
      : []),
    ...(dispenseRequest?.shippingAddress
      ? [
          {
            label: "Shipping Address",
            value: getStringAddress(dispenseRequest?.shippingAddress),
          },
        ]
      : []),
    ...(note?.[0]?.text
      ? [
          {
            label: NOTES_LABEL,
            value: note?.[0]?.text,
          },
        ]
      : []),
  ]
  const bodyTemplate = ({ label, value }: { label: string; value: string }) => {
    if (label === NOTES_LABEL) {
      return <pre className="font-sans text-sm text-wrap">{value}</pre>
    }

    return value
  }

  if (!showAsList)
    return (
      <DataTable value={mrData} showHeaders={false}>
        <Column field="label" className="px-0 py-4 text-gray-600 text-sm font-semibold" style={{ width: "130px" }} />
        <Column field="value" className="py-4 text-black text-sm" body={bodyTemplate} />
      </DataTable>
    )

  return (
    <div className={classNames("flex flex-col", containerClassName)}>
      {!!dosageInstruction?.length && (
        <div className="flex flex-col">
          <h6 className={titleClassName}>Sig</h6>
          <div className={classNames("grid grid-flow-row-dense grid-cols-2 @md:grid-cols-3 gap-x-6", textClassName)}>
            {dosageInstruction?.flatMap(({ text }, index) => <p key={`${index}-${text}`}>{text ?? ""}</p>)}
          </div>
        </div>
      )}
      {dispenseRequest?.quantity?.value && (
        <>
          <div className={titleClassName}>Quantity</div>
          <div className={textClassName}>
            {`${dispenseRequest?.quantity?.value} ${pluralize(
              dispenseRequest?.quantity?.unit ?? "unit",
              dispenseRequest?.quantity?.value,
            )}`}
          </div>
        </>
      )}
      {dispenseOption && (
        <>
          <div className={titleClassName}>Dispense</div>
          <div className={textClassName}>{dispenseOption.label}</div>
        </>
      )}
      <div className={titleClassName}>Refills</div>
      <div className={textClassName}>{dispenseRequest?.numberOfRepeatsAllowed ?? 0} refills</div>
      {dispenseRequest?.nextRefillDate && (dispenseRequest?.numberOfRepeatsAllowed ?? 0) > 0 && (
        <>
          <div className={titleClassName}>Next Refill</div>
          <div className={textClassName}>{`after ${format(
            new Date(dispenseRequest?.nextRefillDate),
            formatsByTypes.LONG_DATE,
          )}`}</div>
        </>
      )}
      {requesterRef?.display && (
        <>
          <div className={titleClassName}>Prescriber</div>
          <div className={textClassName}>{requesterRef.display}</div>
        </>
      )}
      {dispenseRequest?.performer?.display && (
        <>
          <div className={titleClassName}>Pharmacy</div>
          <div className={textClassName}>{dispenseRequest?.performer?.display}</div>
        </>
      )}
      {dispenseRequest?.shippingAddress && (
        <>
          <div className={titleClassName}>Shipping Address</div>
          <div className={textClassName}>{getStringAddress(dispenseRequest?.shippingAddress)}</div>
        </>
      )}
      {note?.[0]?.text && (
        <>
          <div className={titleClassName}>Notes</div>
          <div title="Notes" className="bg-gray-50 rounded-lg p-4">
            {note?.[0]?.text}
          </div>
        </>
      )}
    </div>
  )
}

type Props = {
  medicationRequest?: MedicationRequestInfo
  medicationDispense?: MedicationDispense[]
  titleClassName?: string
  textClassName?: string
  containerClassName?: string
  showAsList?: boolean
}

export { MedicationRequestDetails }
