import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "primereact/menu"
import type { MenuItem as MenuItemProps } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { type ReactHTML, type ReactNode, useRef } from "react"

import { MenuStyles } from "../types"
import { type BadgeProps, Badge } from "./Badge"

const StackedListItem = ({
  modelData: {
    itemClassName,
    leftData,
    rightData,
    image,
    badge,
    menu,
    menuStyle = MenuStyles.Dropdown,
    isLoading,
    columnStyle = "Edge",
    onClick,
    onClickImage,
    lineItemClassname,
  },
  rowHover,
  itemPadding,
  className = "py-4",
  allowExpandItems = false,
  contentClassName,
  customTag = "li",
  keepMenuSpace = true,
}: StackedListProps) => {
  const menuRef = useRef<Menu>(null)

  const Tag = customTag ?? "li"

  return (
    <Tag
      className={classNames("flex pl-3 @md:items-center", className, itemClassName, {
        "hover:bg-gray-50": rowHover,
        "cursor-pointer": onClick,
        "px-4 sm:px-6": itemPadding,
      })}
    >
      {image && (
        <div className={classNames("flex flex-shrink-0", { "cursor-pointer": onClickImage })} onClick={onClickImage}>
          {image}
        </div>
      )}
      <div
        className={classNames(
          "min-w-0 grow",
          {
            "@md:flex @md:justify-between": columnStyle === "Edge",
            "@md:grid @md:grid-cols-2": columnStyle === "Grid",
            "px-4": image,
            "@md:items-center": !rightData || (rightData.length === 1 && leftData && leftData.length <= 2),
          },
          contentClassName,
        )}
        onClick={onClick}
      >
        <div className="truncate">
          {leftData?.map(
            ({ lineItems: lineItem, withVerticalDivider }, line) =>
              lineItem.length > 0 && (
                <div
                  key={`ml${line}`}
                  className={classNames("text-sm flex-wrap gap-1 items-baseline", {
                    flex: !allowExpandItems,
                    "text-gray-500 font-medium": line === 0,
                    "text-gray-300 text-xs mt-1": line !== 0,
                    "divide-x-2": withVerticalDivider,
                  })}
                >
                  {lineItem.map((item, index) =>
                    item.component ? (
                      <span key={`ml${line}i${index}`} className="min-w-0" title={item.name}>
                        {item.component}
                      </span>
                    ) : (
                      <span
                        key={`ml${line}i${index}`}
                        className={classNames("@md:flex items-center truncate", lineItemClassname, {
                          "mt-1 @md:mt-0 @sm:pl-1": index !== 0,
                          "cursor-pointer": item.onClick,
                          "@md:pl-1 @md:first:pl-0": withVerticalDivider,
                          "@md:ml-3": !withVerticalDivider && index !== 0,
                        })}
                        title={item.name ?? item.value}
                        onClick={item.onClick}
                      >
                        {item.icon && (
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                          />
                        )}
                        {item.value}
                      </span>
                    ),
                  )}
                </div>
              ),
          )}
        </div>
        {rightData && (
          <div
            className={classNames("w-fit @md:mt-0 @md:text-right @md:flex @md:flex-col @sm:mt-2", {
              "@md:items-end": columnStyle === "Edge",
              "@md:items-start": columnStyle === "Grid",
              "justify-center": !!badge,
              "mr-11": !!keepMenuSpace && !menu?.length,
            })}
          >
            {badge && (
              <Badge
                className={classNames("@md:inline-flex whitespace-nowrap", {
                  hidden: !rightData,
                })}
                text={badge.text}
                colorStyle={badge.colorStyle}
                size={badge.size}
              />
            )}
            {rightData.map(({ lineItems: lineItem }, line) => (
              <div
                key={`sl${line}`}
                className={classNames("@md:flex text-gray-400 text-xs", {
                  "mt-1": !!badge || line !== 0,
                })}
              >
                {lineItem.map((item, index) =>
                  item.component ? (
                    <span key={`ml${line}i${index}`} className="min-w-0">
                      {item.component}
                    </span>
                  ) : (
                    <p
                      key={`sl${line}i${index}`}
                      className={classNames("@md:flex items-center truncate", {
                        "mt-1 @md:mt-0 @md:ml-3": index !== 0,
                        "cursor-pointer": item.onClick,
                      })}
                      title={item.name ?? item.value}
                      onClick={item.onClick}
                    >
                      {item.icon && (
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                        />
                      )}
                      {item.value}
                    </p>
                  ),
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {badge && (
        <Badge
          className={classNames(
            "h-fit whitespace-nowrap flex justify-end",
            {
              hidden: rightData,
              "mr-11": !!keepMenuSpace && !menu?.length,
            },
            badge.className,
          )}
          text={badge.text}
          colorStyle={badge.colorStyle}
          size={badge.size}
        />
      )}
      {(isLoading || (menu && menu.length > 0)) && (
        <div className="flex flex-shrink-0 ml-3 mr-1 text-gray-500 text-sm self-center">
          {isLoading ? (
            <span className="flex justify-center items-center">
              <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" size="lg" />
            </span>
          ) : menuStyle === MenuStyles.ExternalAction ? (
            <div className="flex gap-2 items-center">
              {menu?.map((menuItem, index) => (
                <span
                  key={index}
                  className={classNames("w-5 h-5 flex items-center justify-end", {
                    "cursor-pointer": !menuItem.disabled,
                  })}
                  title={menuItem.label}
                  onClick={(event) => {
                    !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                  }}
                >
                  {menuItem.loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin className="fa-fw text-gray-400" />
                  ) : (
                    menuItem.icon ?? <FontAwesomeIcon icon={faExternalLink} className="fa-fw text-gray-400" />
                  )}
                </span>
              ))}
            </div>
          ) : menuStyle === MenuStyles.ActionItems ? (
            menu?.map((menuItem, index) => (
              <span
                className={classNames(
                  "mx-1 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:border hover:border-gray-200 w-6 h-6",
                  { "cursor-pointer": !menuItem.disabled },
                )}
                title={menuItem.label}
                onClick={(event) => {
                  !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                }}
                key={menuItem.label ?? index}
              >
                {menuItem.icon ?? menuItem.label}
              </span>
            ))
          ) : (
            <span
              className="cursor-pointer flex hover:bg-gray-100 p-1 rounded-full"
              title="Menu"
              onClick={(event) => {
                menuRef.current && menuRef.current.toggle(event)
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} size="lg" className="fa-fw" />
              <Menu model={menu} popup ref={menuRef} id="popup_menu" style={{ fontSize: "small" }} />
            </span>
          )}
        </div>
      )}
    </Tag>
  )
}

export type CommonProps = {
  /** Apply row hovered style */
  rowHover?: boolean
  /** Apply horizontal padding */
  itemPadding?: boolean
  allowExpandItems?: boolean
}

export type StackedListProps = CommonProps & {
  className?: string
  modelData: StackedListItemProps
  contentClassName?: string
  customTag?: keyof ReactHTML
  keepMenuSpace?: boolean
}

export type StackedListItemProps = {
  itemClassName?: string
  lineItemClassname?: string
  /** Image */
  image?: ReactNode
  onClickImage?(): void
  /** Left Column Data */
  leftData?: StackedLineItemProps[]
  /** Right Column Data */
  rightData?: StackedLineItemProps[]
  badge?: BadgeProps
  /** Dropdown menu */
  menu?: MenuItem[]
  menuStyle?: MenuStyles
  isLoading?: boolean
  columnStyle?: "Edge" | "Grid"
  onClick?(): void
}

type MenuItem = MenuItemProps & { loading?: boolean }

export type StackedLineItemProps = {
  lineItems: StackedItemProps[]
  withVerticalDivider?: boolean
}

export type StackedItemProps = {
  name?: string
  value?: string
  component?: JSX.Element
  icon?: IconDefinition
  onClick?(): void
}

export { StackedListItem }
