import { useFormikContext } from "formik"
import { Calendar } from "primereact/calendar"
import { classNames } from "primereact/utils"
import { type FC, useMemo } from "react"

import { useFindAvailableSlots, useScheduleAppointmentContext } from "../hooks"
import type { AppointmentFormData } from "../types"
import { getMonthDateBounds } from "../utils"
import "./AppointmentCalendar.css"

const AppointmentCalendar: FC = () => {
  const {
    values: { patient, practitioner, healthcareService },
  } = useFormikContext<AppointmentFormData>()
  const {
    selectedMonthBounds: { start, end },
    updateSelectedMonthBounds,
    selectedDate,
    updateSelectedDate,
  } = useScheduleAppointmentContext()

  const { slots } = useFindAvailableSlots({
    healthcareService,
    startDate: start,
    endDate: end,
    patient,
    practitioner,
  })
  const enabledDates = useMemo(() => slots.map((slot) => new Date(slot.start)), [slots])
  const isDisabled = !healthcareService || !patient || !practitioner

  return (
    <Calendar
      value={selectedDate}
      className="custom-appointment-calendar-sm"
      panelClassName={classNames("border-none border-0", { "bg-opacity-40": isDisabled })}
      onChange={(e) => {
        updateSelectedDate(e.value as Date)
      }}
      inline
      disabled={isDisabled}
      onMonthChange={(e) => {
        updateSelectedMonthBounds(getMonthDateBounds(e))
      }}
      enabledDates={enabledDates}
    />
  )
}

export { AppointmentCalendar }
