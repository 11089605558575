import { useQuery } from "@tanstack/react-query"
import {
  asReference,
  CodeableConcept,
  Device,
  getResources,
  getResourcesByTypeAsIndex,
  HealthcareService,
  isDevice,
  isLocation,
  isPlanDefinition,
  isPractitioner,
  Location,
  Practitioner,
  Reference,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { PD_ACTION_DYNAMIC_VALUE } from "data"

import { patientAptsQueryKeys } from "../query-keys"
import { APPOINTMENT_TYPE_CHARACTERISTIC, AppointmentType } from "../types"

const useAppointmentTypes = (organizationId: string) => {
  const { search } = useClient()
  const queryKey = patientAptsQueryKeys.appointmentTypes(organizationId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        "active:not": "false",
        _elements: "id,name,characteristic,contained,participant",
        _count: "100",
        _sort: "name",
        _include: "participant",
        organization: organizationId,
      })

      const bundle = await search({ endpoint: `HealthcareService`, filters, signal })
      const healthcareServices = getResources<HealthcareService>(bundle, "HealthcareService")
      const rooms = getResourcesByTypeAsIndex<Location>(bundle, "Location")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const devices = getResourcesByTypeAsIndex<Device>(bundle, "Device")

      return { healthcareServices, rooms, devices, practitioners }
    },
    meta: { context: { queryKey, organizationId } },
  })

  const defaultTypeData = {
    practitioners: Array<Reference>(),
    rooms: Array<Reference>(),
    devices: Array<Reference>(),
  }
  const defaultCharacteristics = {
    telemedicine: false,
    patientCanBook: false,
    color: undefined as string | undefined,
  }

  const { appointmentTypeRefs, appointmentTypes } = useMemo(() => {
    const types = data?.healthcareServices.reduce((acc, apptType) => {
      const { practitioners, devices, rooms } =
        apptType.participant?.reduce((participants, participant) => {
          if (isPractitioner(participant)) {
            const part = data.practitioners[participant.id as string]
            return {
              ...participants,
              practitioners: [...participants.practitioners, ...(part ? [asReference(part)] : [])],
            }
          } else if (isLocation(participant)) {
            const part = data.rooms[participant.id as string]
            return { ...participants, rooms: [...participants.rooms, ...(part ? [asReference(part)] : [])] }
          } else if (isDevice(participant)) {
            const part = data.devices[participant.id as string]
            return { ...participants, devices: [...participants.devices, ...(part ? [asReference(part)] : [])] }
          }
          return { ...participants }
        }, defaultTypeData) ?? defaultTypeData

      const { telemedicine, patientCanBook, color } =
        apptType.characteristic?.reduce((acc, char) => {
          if (char.coding?.some(({ code }) => code === APPOINTMENT_TYPE_CHARACTERISTIC.telemedicine))
            return { ...acc, telemedicine: true }
          if (char.coding?.some(({ code }) => code === APPOINTMENT_TYPE_CHARACTERISTIC.patientCanBook))
            return { ...acc, patientCanBook: true }
          if (char.coding?.some(({ system }) => system === APPOINTMENT_TYPE_CHARACTERISTIC.color))
            return { ...acc, color: char.coding?.[0]?.code }

          return { ...acc }
        }, defaultCharacteristics) ?? defaultCharacteristics

      const actions = isPlanDefinition(apptType.contained?.[0])
        ? apptType.contained?.[0]?.action?.reduce(
            (acc, act) => {
              if (act.code?.[0]?.coding?.some(({ code }) => code === "questionnaires")) {
                return {
                  ...acc,
                  questionnaires: act.action?.reduce((qs, { definition, description }) => {
                    return [
                      ...qs,
                      ...(definition?.canonical
                        ? [{ id: definition?.canonical, display: description, resourceType: "Questionnaire" }]
                        : []),
                    ]
                  }, Array<Reference>()),
                }
              }

              if (act.code?.[0]?.coding?.some(({ code }) => code === "labs")) {
                const reasonCodes: CodeableConcept[] = JSON.parse(
                  act.dynamicValue?.find(({ path }) => path === PD_ACTION_DYNAMIC_VALUE.REASON_CODE)?.expression
                    ?.expression ?? "[]",
                )

                return { ...acc, icd10: reasonCodes }
              }

              return acc
            },
            {} as Pick<AppointmentType, "questionnaires" | "icd10">,
          )
        : undefined

      return [
        ...acc,
        {
          ...apptType,
          telemedicine,
          patientCanBook,
          practitioners,
          devices,
          rooms,
          color,
          ...(actions ?? {}),
        },
      ]
    }, Array<AppointmentType>())

    return { appointmentTypes: types, appointmentTypeRefs: types?.flatMap((t) => asReference(t)) }
  }, [data])

  return { appointmentTypes, appointmentTypeRefs, isLoading, refetch }
}

export { useAppointmentTypes }
