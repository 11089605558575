import { useQuery } from "@tanstack/react-query"
import {
  Appointment,
  asReference,
  CarePlan,
  CarePlanActivityArray,
  CodeableConcept,
  CommunicationRequest,
  Composition,
  getResources,
  getResourcesByTypeAsIndex,
  Money,
  Organization,
  PlanDefinition,
  PlanDefinitionActionArrayActionArray,
  Reference,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { MC_ACTIVITY_TYPE } from "data"
import { mergeSort } from "utils"

import { plansQueryKeys } from "../query-keys"
import { ComboDefinition, MailTaskData, PLAN_ACTION_CODES, PlanData } from "../types"
import { getActionCode, getComboDefinition, isPDType } from "../utils"

const useCarePlans = ({
  patientId,
  enableQuery = true,
  planId,
  searchText,
  statusFilter,
  encounter,
}: {
  patientId: string
  enableQuery?: boolean
  planId?: string
  searchText?: string
  statusFilter?: string[]
  intent?: string
  encounter?: string
}) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.list(patientId, planId, searchText, statusFilter, encounter)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    enabled: enableQuery,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-plans",
        patient: patientId,
        "exclude-mc": "true",
        ...(encounter ? { encounter } : {}),
        ...(searchText ? { title: searchText } : {}),
        ...(statusFilter?.length ? { status: statusFilter.join(",") } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplans = getResources<CarePlan>(bundle, "CarePlan")
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const tasks = getResources<Task>(bundle, "Task")
      const appointments = getResourcesByTypeAsIndex<Appointment>(bundle, "Appointment")
      const catalogs = getResourcesByTypeAsIndex<Composition>(bundle, "Composition")
      const organizations = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")

      return {
        careplans,
        planDefinitions,
        tasks,
        appointments,
        organizations,
        catalogs,
        total: bundle.total,
      }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { plans, activePDs, activeCPCount } = useMemo(() => {
    const carePlans = data?.careplans
    const organizations = data?.organizations ?? {}
    const catalogs = data?.catalogs ?? {}

    const { planDefinitions, pdReplacementToken } = data?.planDefinitions?.reduce<PD_Data>(
      (acc, pd) => {
        const token = `${pd.url}|${pd.version}`
        if (["follow-up", "clinical-protocol"].includes(pd.type?.coding?.[0]?.code as string)) {
          const fuToken = isPDType(pd, "clinical-protocol")
            ? pd.action?.find((action) => action.code?.[0]?.coding?.[0]?.code === "enroll-follow-up-plan")?.definition
                ?.canonical
            : undefined
          return {
            pdReplacementToken: !fuToken ? acc.pdReplacementToken : { ...acc.pdReplacementToken, [fuToken]: token },
            planDefinitions: { ...acc.planDefinitions, [token]: pd },
          }
        } else return { ...acc, planDefinitions: { ...acc.planDefinitions, [token]: pd } }
      },
      { planDefinitions: {}, pdReplacementToken: {} },
    ) ?? { planDefinitions: {}, pdReplacementToken: {} }

    const indexedTasks =
      data?.tasks?.reduce<Record<string, Task>>(
        (acc, task) =>
          task.code?.coding?.[0]?.code === "send-scheduled-email" ? { ...acc, [task.id as string]: task } : acc,
        {},
      ) ?? {}

    const init = {
      plans: [] as PlanData[],
      activePDs: {} as Record<string, string>,
      activeCPCount: 0,
    }

    const plansData =
      carePlans?.reduce((acc, carePlan) => {
        const pdToken = carePlan.instantiatesCanonical?.[0] as string
        const planDefinition = planDefinitions[pdToken]

        const mailTasks =
          carePlan.status === "draft"
            ? carePlan.activity?.reduce<Record<string, MailTaskData>>((accTasks, acItem, index, activities) => {
                const task =
                  indexedTasks[acItem.reference?.resourceType === "Task" ? (acItem.reference.id as string) : ""]

                if (!task) return accTasks

                const links = (task?.contained?.[0] as CommunicationRequest)?.template?.data?.links
                const display = (Array.isArray(links) && links[0]?.display) ?? ""

                const contentRef = activities[index - 1]?.outcomeReference?.[0]

                const defAct = planDefinition.action?.find(
                  (ac) =>
                    ac.code?.[0]?.coding?.[0]?.code === "send-email" &&
                    ac.action?.[0]?.output?.[0]?.type === contentRef?.resourceType &&
                    ac.action?.[0]?.output?.[0]?.codeFilter?.[0]?.code?.[0]?.display === contentRef?.display,
                )
                const isRequired = defAct?.requiredBehavior === "must"

                return task
                  ? {
                      ...accTasks,
                      [task.id as string]: {
                        taskId: task.id as string,
                        restriction: { period: { start: task.restriction?.period?.start ?? undefined } },
                        label: display,
                        activityIndex: index,
                        enabled: acItem.enabled ?? false,
                        isRequired,
                      },
                    }
                  : accTasks
              }, {}) ?? {}
            : {}

        const appointmentId =
          carePlan.activity?.find((a) => a.outcomeReference?.[0]?.resourceType === "Appointment")?.outcomeReference?.[0]
            ?.id ?? ""
        const appointment = data?.appointments[appointmentId]

        const actions =
          planDefinition.action?.reduce(
            (acc, action) => {
              const code = getActionCode(action.code?.[0])
              if (code) return { ...acc, [code]: action }
              else return { ...acc }
            },
            {} as Record<string, PlanDefinitionActionArrayActionArray>,
          ) ?? {}

        const actionLab: PlanDefinitionActionArrayActionArray | undefined = actions[PLAN_ACTION_CODES.CONFIGURE_LABS]
        const actionSendMails: PlanDefinitionActionArrayActionArray | undefined =
          actions[PLAN_ACTION_CODES.CONFIGURE_QUESTIONNAIRES]
        const daysBeforeToMail = actionSendMails?.dynamicValue?.find((v) => v.path === "daysBeforeAppointment")
          ?.expression?.expression
        const actionAlgorithm = actions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]
        const algorithmActivity = actionAlgorithm?.action?.reduce<CarePlanActivityArray[]>((acts, act) => {
          const activityPD = planDefinitions[act.definition?.canonical as string]

          return activityPD
            ? [
                ...acts,
                {
                  enabled: true,
                  outcomeCodeableConcept: act.code,
                  outcomeReference: activityPD
                    ? [
                        {
                          resourceType: "PlanDefinition",
                          id: activityPD.id,
                          display: activityPD.title ?? activityPD.name,
                        } as Reference,
                      ]
                    : [],
                  detail: {
                    status: "in-progress",
                    kind: activityPD.name?.includes("survey") ? MC_ACTIVITY_TYPE.MC_SURVEY : MC_ACTIVITY_TYPE.MC,
                    instantiatesCanonical: [act.definition?.canonical as string],
                  },
                },
              ]
            : [...acts]
        }, [])

        const planReasonCodes = actionLab?.dynamicValue?.find((v) => v.path === "reasonCode")?.expression?.expression
        const icd10 = planReasonCodes ? (JSON.parse(planReasonCodes) as CodeableConcept) : undefined

        const combos =
          actionLab?.action
            ?.find((a) => a.title === "Configure Combo")
            ?.action?.reduce<Array<ComboDefinition>>((combos, pdRef) => {
              const comboPanel = planDefinitions[pdRef.definition?.canonical as string]
              const comboDef = comboPanel
                ? getComboDefinition({
                    comboPanel,
                    panelDefinitions: planDefinitions,
                    catalogs,
                    cids: {},
                    organizations,
                  })
                : ({} as ComboDefinition)

              return comboPanel ? [...combos, comboDef] : combos
            }, []) ?? []

        /* Handle sort combos by price. Promo combos at end */
        const { withPromo, sortedOnes } = mergeSort(
          combos,
          "price",
          (a: Money | undefined, b: Money | undefined) => (a?.value ?? 0) - (b?.value ?? 0),
        ).reduce(
          (acc, comboDef) => {
            const isPromoCombo = !!comboDef.promoCoding
            if (isPromoCombo) {
              acc.withPromo.push(comboDef)
            } else {
              acc.sortedOnes.push(comboDef)
            }
            return acc
          },
          { withPromo: [] as ComboDefinition[], sortedOnes: [] as ComboDefinition[] },
        )

        return {
          plans: [
            ...acc.plans,
            {
              carePlan,
              definitionToken: pdToken,
              planDefinition,
              mailTasks,
              appointment,
              icd10,
              combos: [...sortedOnes, ...withPromo],
              performers: [
                ...Object.values(organizations)
                  .filter(({ type }) =>
                    type?.some(({ coding }) => coding?.some(({ code }) => code === "facility-provider")),
                  )
                  .flatMap((o) => asReference(o)),
              ],
              daysBeforeToMail: daysBeforeToMail ? parseInt(daysBeforeToMail) : undefined,
              algorithmActivity,
              configureActions: { ...actions },
            },
          ],
          activePDs: ["draft", "active"].includes(carePlan.status)
            ? {
                ...acc.activePDs,
                [pdToken]: carePlan.status,
                ...(pdReplacementToken[pdToken] ? { [pdReplacementToken[pdToken]]: carePlan.status } : {}),
              }
            : acc.activePDs,
          activeCPCount: acc.activeCPCount + (carePlan.status === "active" ? 1 : 0),
        }
      }, init) ?? init

    return plansData
  }, [data])

  return {
    plans,
    activePDs,
    activeCPCount,
    isLoading,
    isFetching,
    total: data?.total ?? plans.length,
  }
}

type PD_Data = {
  planDefinitions: Record<string, PlanDefinition>
  pdReplacementToken: Record<string, string>
}

export { useCarePlans }
