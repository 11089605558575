import { useQuery } from "@tanstack/react-query"
import { type Parameters } from "fhir"

import { useClient } from "api"

import { commonsQueryKeys } from "../query-keys"
import { type ProductConfigurationToRequest } from "../types"
import { generateProductPricesParameters, formatProductPricesResponse } from "../utils"

const useProductPrices = ({ organizationId, productsConfigurations }: UseProductPricesParams) => {
  const getProductPrices = useProductPricesQueryFunction()
  const queryKey = commonsQueryKeys.productPrices(organizationId, productsConfigurations)

  // TODO: Add optimizations so in a user session
  // any product price is only requested once
  // but invalidate cache on windows focus
  // and use as initial data for the query the
  // data that is already cached
  const { data: productPrices, isLoading } = useQuery({
    queryKey,
    queryFn: async () => getProductPrices({ organizationId, productsConfigurations }),
    enabled: productsConfigurations.length > 0,
    meta: { context: { queryKey, productsConfigurations } },
  })

  return { productPrices, isLoading }
}

const useProductPricesQueryFunction = ({ includeAllFees = false }: { includeAllFees?: boolean } = {}) => {
  const { operationRequest } = useClient()

  return async ({ organizationId, productsConfigurations }: UseProductPricesParams) => {
    const paramaters: Parameters = generateProductPricesParameters(productsConfigurations, includeAllFees)

    const paramatersBundle = await operationRequest<Parameters>({
      endpoint: `Organization/${organizationId}`,
      method: "POST",
      operation: "product-prices",
      parameters: paramaters,
    })

    return formatProductPricesResponse(paramatersBundle)
  }
}

type UseProductPricesParams = {
  organizationId: string
  productsConfigurations: ProductConfigurationToRequest[]
}

export { useProductPrices, useProductPricesQueryFunction }
