import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChargeItemDefinition } from "fhir"
import { classNames } from "primereact/utils"

import { systems } from "data"
import { getFeePrice, getMoneyCurrencyAlt } from "utils"

const MIN_AMOUNT_FREE_ORDER = 100

const OrderShippingItem = ({
  shipping,
  selected,
  hasDropShip,
  totalItemsCost,
  isSelectionDisabled,
  selectShipping,
}: Props) => {
  const isFreeShepping = shipping.code?.coding?.some(({ code }) => code === "free-shipping")
  const basePrice = getFeePrice(shipping.propertyGroup?.[0].priceComponent)
  const price =
    totalItemsCost > MIN_AMOUNT_FREE_ORDER && isFreeShepping && hasDropShip ? 0 : basePrice?.amount?.value ?? 0
  const feeDisplay = shipping.code?.coding?.find((coding) => coding.system === systems["shippingMethod"])?.display

  return (
    <div
      className={classNames(
        "relative flex  rounded-lg border bg-white p-4 shadow-sm focus:outline-none hover:shadow-md",
        selected ? "border-transparent ring-1 ring-primary" : "border-gray-300",
        { "cursor-pointer": !isSelectionDisabled && !selected },
      )}
      onClick={() => !isSelectionDisabled && selectShipping()}
    >
      <div className="flex flex-1">
        <div className="flex flex-col">
          <span className="font-medium text-gray-900 text-sm">{feeDisplay}</span>
          <span className="mt-1 flex items-center text-sm text-gray-500">{shipping.code?.text}</span>
          <span className="mt-6 text-sm font-medium text-gray-900">
            {getMoneyCurrencyAlt(basePrice?.amount?.currency)}
            {price.toFixed(2)}
          </span>
        </div>
      </div>
      {selected && <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 text-primary" />}
      <span
        className={classNames(
          "pointer-events-none absolute -inset-px rounded-lg",
          selected ? "border border-primary" : "border-2 border-transparent",
        )}
      />
    </div>
  )
}

type Props = MedShippingCondition & {
  shipping: ChargeItemDefinition
  selected: boolean
  selectShipping(): void
  isSelectionDisabled?: boolean
}

export type MedShippingCondition = {
  hasDropShip: boolean
  totalItemsCost: number
}

export { OrderShippingItem }
