import { useSuspenseQuery } from "@tanstack/react-query"
import { getResource, getResources, isLocation, Location, Organization } from "fhir"

import { useClient } from "api"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationKeys } from "../query-keys"

const useOrganization = (organizationId: string) => {
  const { search } = useClient()
  const queryKey = organizationKeys.detail(organizationId)

  const filters = new URLSearchParams({
    _id: organizationId,
    _revinclude: "Location:organization:Organization",
  })

  const { data, isLoading, isError, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const bundle = await search({ endpoint: "Organization", filters })

      const organization = getResource<Organization>(bundle, "Organization")
      const locations = getResources<Location>(bundle, "Location")

      const isActive = organization.active !== undefined ? organization.active : true

      if (!organization || !isActive) {
        throw registerErrorTrace(
          new Error("Not found", {
            cause: {
              name: "404",
              message: "Organization not found",
              logoutFlag: true,
            },
          }) as CustomError,
        )
      }

      const { location } =
        locations?.reduce<{ location: Location | undefined }>(
          (prev, location) => {
            if (isLocation(location)) {
              if (location.physicalType?.coding?.[0]?.code !== "ro") {
                return { ...prev, location: location }
              }
            }

            return prev
          },
          { location: undefined },
        ) ?? {}

      return { organization, location }
    },
    meta: { context: { queryKey } },
  })

  if (isError) {
    throw error
  }

  return { organization: data?.organization as Organization, location: data.location, isLoading }
}

export { useOrganization }
