import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isPractitioner } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useMemo } from "react"

import { ReferenceDropdownField } from "commons"

import { telemedicineServiceCC } from "../data"
import { useAppointmentTypes, useScheduleAppointmentContext } from "../hooks"
import type { AppointmentFormData } from "../types"
import { AppointmentCalendar } from "./AppointmentCalendar"

const AppointmentCalendarSection: FC<Props> = ({ organizationId }) => {
  const { appointmentTypes, appointmentTypeRefs } = useAppointmentTypes(organizationId)

  const { updateSelectedDate } = useScheduleAppointmentContext()

  const {
    values: { healthcareService },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const selectedHealthcareService = useMemo(
    () => appointmentTypes?.find(({ id }) => id === healthcareService?.id),
    [appointmentTypes, healthcareService],
  )
  const practitionersRefs = useMemo(
    () => selectedHealthcareService?.participant?.filter(isPractitioner) ?? [],
    [selectedHealthcareService],
  )
  const duration = selectedHealthcareService?.duration

  useEffect(() => {
    if (duration?.value) setFieldValue("minutesDuration", duration?.value)
  }, [duration])

  useEffect(() => {
    const isTelemedicineAppointment = selectedHealthcareService?.characteristic?.some(
      ({ coding }) => coding?.[0]?.code === "telemedicine",
    )
    setFieldValue("virtualService", isTelemedicineAppointment ? [telemedicineServiceCC] : undefined)
  }, [selectedHealthcareService])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <ReferenceDropdownField
          field="healthcareService"
          label="Appointment Type"
          options={appointmentTypeRefs}
          className="w-full"
          showFilter={false}
          validation={(val) => (!val?.id ? "Appointment type is required" : undefined)}
          handleChange={() => updateSelectedDate(undefined)}
        />
        <div
          className={classNames("flex items-center -mt-3 text-gray-400 gap-1 text-sm h-4", {
            hidden: !duration,
          })}
        >
          <FontAwesomeIcon icon={faClock} size="sm" />
          {duration?.value} {duration?.unit} session
        </div>
      </div>

      <ReferenceDropdownField
        field="practitioner"
        label="Practitioner"
        options={practitionersRefs}
        className="w-full"
        isClearable
        disabled={!selectedHealthcareService}
        handleChange={() => updateSelectedDate(undefined)}
      />
      <AppointmentCalendar />
    </div>
  )
}

type Props = {
  organizationId: string
}

export { AppointmentCalendarSection }
