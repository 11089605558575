import { codeableConceptAsString, MedicationKnowledge, Money, Duration } from "fhir"
import pluralize from "pluralize"

import { MedicationKnowledgeImage } from "commons/meds"
import { getMoneyCurrencyAlt } from "utils"
import { CartMedData } from "orders"

import { OverviewFooter } from "./OverviewFooter"

const MedicationKnowledgeCard = ({
  medicationKnowledge,
  pricePerUnit,
  onAdd,
  onEditDispense,
  medData,
  isLoadingPrice,
  blockAdd,
  isProcessing,
  onClick,
  onDelete,
}: Props) => {
  const price = isLoadingPrice
    ? "Loading price..."
    : pricePerUnit?.value
      ? `${getMoneyCurrencyAlt(pricePerUnit.currency)}${pricePerUnit.value.toFixed(2)}`
      : "No price"

  return (
    <div className="flex flex-col gap-2 w-72 mx-auto grow">
      <div
        className="border border-gray-200 bg-gray-100 rounded-md w-72 h-72 flex items-center justify-center cursor-pointer relative overflow-hidden"
        onClick={onClick}
      >
        <MedicationKnowledgeImage drugCharacteristic={medicationKnowledge?.drugCharacteristic} className="h-full" />
        <span className="absolute flex bottom-0 w-full rounded-b-md h-10 bg-gray-500 opacity-50 items-center justify-center text-white font-medium">
          See details
        </span>
      </div>
      <div className="flex flex-col text-sm grow">
        <div className="text-gray-900 cursor-pointer text-ellipsis line-clamp-1" onClick={onClick}>
          <span>{codeableConceptAsString(medicationKnowledge?.code)}</span>
          {medicationKnowledge?.packaging?.quantity && (
            <span>
              {" | "}
              {medicationKnowledge.packaging.quantity.value}{" "}
              {pluralize(
                medicationKnowledge.packaging.quantity.unit?.replace(/{|}/g, "") ?? "item",
                medicationKnowledge.packaging.quantity.value,
              )}
            </span>
          )}
        </div>
        {medicationKnowledge?.manufacturer?.id && (
          <div className="text-gray-500 truncate">{medicationKnowledge.manufacturer.display}</div>
        )}
        {medData?.requestedByPract && (
          <div className="text-orange-400 mt-2 text-xs truncate">Prescribed by your practitioner</div>
        )}
        {medData?.isDfo && <div className="text-orange-400 mt-2 text-xs truncate">Pick up at office</div>}
      </div>
      <OverviewFooter
        medData={medData}
        price={price}
        onAdd={(units, duration) => onAdd(medicationKnowledge, units, duration)}
        onEdit={(units, duration) => onEditDispense(medicationKnowledge, units, duration)}
        onDelete={onDelete}
        blockAdd={blockAdd}
        isProcessing={isProcessing}
        disabled={medData?.isDfo}
        disabledReason="This item is only editable by your practitioner"
      />
    </div>
  )
}

type Props = {
  medicationKnowledge: MedicationKnowledge
  blockAdd?: boolean
  medData?: CartMedData
  isLoadingPrice?: boolean
  isProcessing?: boolean
  pricePerUnit?: Money
  onAdd(mk: MedicationKnowledge, unitsCount: number, duration: Duration): void
  onEditDispense(mk: MedicationKnowledge, unitsCount: number, duration: Duration): void
  onDelete(mrId: string): void
  onClick(): void
}

export { MedicationKnowledgeCard }
