import { FC, useMemo } from "react"

import { SkeletonLoader } from "commons"
import { LaboratoryOrderResults } from "laboratory/components/LaboratoryOrderResults"
import { useLaboratoryOrder } from "laboratory/hooks"

const CPLabResultsDetails: FC<Props> = ({ patientId, labOrderId, currentOrganizationId }) => {
  const { laboratoryOrder, isLoading: isLoadingLabResults } = useLaboratoryOrder(
    currentOrganizationId,
    labOrderId as string,
    patientId,
  )

  const { insuranceLab: isInsuranceLab, billToPracticeLab: isBillToPracticeLab } = useMemo(() => {
    return {
      insuranceLab: laboratoryOrder.billingType === "insurance",
      billToPracticeLab: laboratoryOrder.billingType === "bill-practice",
    }
  }, [laboratoryOrder.billingType])

  return (
    <div className="py-3 flex flex-col space-y-3">
      <h3 className="text-gray-500 font-medium text-sm">Results</h3>
      {isLoadingLabResults ? (
        <SkeletonLoader loaderType="min-card" repeats={1} />
      ) : (
        <LaboratoryOrderResults
          panels={laboratoryOrder.panels}
          combo={laboratoryOrder.combo}
          previousResults={laboratoryOrder.previousResults}
          hidePrices={isBillToPracticeLab || isInsuranceLab}
        />
      )}
    </div>
  )
}

type Props = { patientId: string; labOrderId?: string; currentOrganizationId: string }

export { CPLabResultsDetails }
