import { faCheck, faEye, faStop, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationRequestMedication, ResourceObject, codeableConceptAsString, isCarePlan } from "fhir"
import pluralize from "pluralize"

import { formatsByTypes } from "data"
import { MenuStyles, StackedListItemProps, MedicationRequestData, MedicationRequestInfo } from "commons"
import { getBadgeColor, getDateLabel, getMoneyCurrencyAlt, getMRStatus, strCapitalize } from "utils"
import { SYSTEM_VALUES } from "system-values"
import { getDispenseFrequency, getMRDispenseInfo } from "./utils"

export const prescriptionItemModel = ({
  mrData,
  externalAction,
  discard,
  stop,
  complete,
  showDetails,
  preview,
  showStatusBadge = true,
  showAsExternalAction = false,
  loading,
  hiddenPrices,
}: {
  mrData: MedicationRequestData
  discard?: (mrId: string) => void
  stop?: (mrId: string) => void
  complete?: (mrId: string) => void
  preview?: (mrData: MedicationRequestData) => void
  showDetails?: (mr: MedicationRequestInfo) => void
  externalAction?: StackedListItemProps["menu"]
  showStatusBadge?: boolean
  showAsExternalAction?: boolean
  loading?: boolean
  hiddenPrices?: boolean
  allowEdit?: boolean
}): StackedListItemProps => {
  const { medicationRequestInfo, medicationKnowledge, medicationDispense, patientPrice } = mrData

  const prescriptionsStatus = getMRStatus(medicationRequestInfo)

  const planBased = medicationRequestInfo?.basedOn?.some(isCarePlan) ?? false
  const disabledAction = planBased || prescriptionsStatus !== "draft"
  const isStopped = prescriptionsStatus === "stopped"
  const isCompleted = prescriptionsStatus === "completed"

  const isWriteIn = medicationRequestInfo.medication?.Reference?.localRef !== undefined
  const medication =
    medicationRequestInfo?.medication?.Reference?.localRef && medicationRequestInfo?.contained
      ? (medicationRequestInfo?.contained?.find(
          (resource) => (resource as ResourceObject)?.id === medicationRequestInfo?.medication?.Reference?.localRef,
        ) as MedicationRequestMedication)
      : (medicationRequestInfo?.medication as MedicationRequestMedication)
  const lfID = medicationKnowledge?.code?.coding?.find(
    (c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION_SYSTEM,
  )?.code
  const ndc = medicationKnowledge?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC_SYSTEM)?.code

  const {
    refills: refillsCount,
    nextRefill,
    refillsLeft,
  } = getMRDispenseInfo(medicationRequestInfo.status, medicationRequestInfo.dispenseRequest, medicationDispense)
  const dispenseRequest = medicationRequestInfo?.dispenseRequest
  const dispense = getDispenseFrequency(dispenseRequest?.dispenseInterval)?.label
  const createdAt = medicationRequestInfo.meta?.createdAt

  return {
    leftData: [
      {
        lineItems: [
          {
            component: (
              <div className="flex items-baseline gap-2">
                <span
                  className={preview && "cursor-pointer"}
                  onClick={(e) => {
                    e.stopPropagation()
                    preview?.(mrData)
                  }}
                >
                  {medicationKnowledge
                    ? strCapitalize(codeableConceptAsString(medicationKnowledge?.code) as string)
                    : strCapitalize(codeableConceptAsString(medication?.CodeableConcept)) ?? "Unspecified"}
                </span>
                {medicationKnowledge?.ingredient?.length === 1 &&
                  medicationKnowledge?.ingredient[0]?.strength?.numerator?.unit && (
                    <span className="text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                      {medicationKnowledge?.ingredient[0].strength.numerator.unit}
                    </span>
                  )}
              </div>
            ),
          },
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          ...(medicationKnowledge?.manufacturer?.display
            ? [
                {
                  name: "Manufacturer",
                  value: `Pharmacy: ${medicationKnowledge.manufacturer.display.replace("Farmakeio", "FarmaKeio")}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.doseForm?.coding?.[0]?.display
            ? [
                {
                  name: "Form",
                  value: `Drug Form: ${medicationKnowledge?.doseForm?.coding?.[0]?.display}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.amount?.value && medicationKnowledge?.amount?.unit
            ? [
                {
                  name: "Amount",
                  value: `Amount: ${medicationKnowledge?.amount?.value} ${medicationKnowledge?.amount?.unit}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.packaging?.type?.coding?.[0]?.display
            ? [
                {
                  name: "Packaging",
                  value: `Pkg: ${medicationKnowledge?.packaging?.type?.coding?.[0]?.display}`,
                },
              ]
            : []),
          ...(lfID
            ? [
                {
                  name: "ID",
                  value: `ID: ${lfID}`,
                },
              ]
            : []),
          ...(ndc
            ? [
                {
                  name: "NDC",
                  value: `NDC: ${ndc}`,
                },
              ]
            : []),
          ...(isWriteIn
            ? [
                {
                  name: "Type",
                  value: "Write-in",
                },
              ]
            : []),
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          {
            name: "Quantity",
            value: `Quantity: ${dispenseRequest?.quantity?.value} ${
              dispenseRequest?.quantity?.unit ?? pluralize("unit", dispenseRequest?.quantity?.value)
            }`,
          },
          ...(dispense
            ? [
                {
                  name: "Dispense",
                  value: `Dispense: ${dispense}`,
                },
              ]
            : []),
          {
            name: "Refills",
            value: `Refills: ${refillsCount} ${pluralize("refill", refillsCount)}`,
          },
          {
            name: "Dispense",
            value: `Refills left:  ${refillsLeft}`,
          },
          ...(nextRefill
            ? [
                {
                  name: "Next Refill",
                  value: `Next Refill: after ${getDateLabel(nextRefill, formatsByTypes.LONG_DATE)}`,
                },
              ]
            : []),
          ...(createdAt
            ? [
                {
                  name: "Created",
                  value: `Created: ${getDateLabel(createdAt.toString(), formatsByTypes.LONG_DATE)}`,
                },
              ]
            : []),
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          ...(!hiddenPrices && patientPrice
            ? [
                {
                  name: "Price",
                  value: `Price: ${getMoneyCurrencyAlt(patientPrice?.currency)}${patientPrice?.value?.toFixed(2)}`,
                },
              ]
            : []),
        ],
      },
    ],
    onClick: showDetails ? () => showDetails(medicationRequestInfo) : () => {},
    badge: showStatusBadge ? getBadgeColor(prescriptionsStatus) : undefined,
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    isLoading: loading,
    menu:
      showDetails || discard || stop || externalAction?.length || complete
        ? [
            ...(externalAction ?? []),
            ...(showDetails
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faEye} />,
                    label: "Show details",
                    command: () => showDetails(medicationRequestInfo),
                  },
                ]
              : []),
            ...(stop
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faStop} />,
                    label: "Stop",
                    command: () => stop(medicationRequestInfo.id ?? ""),
                    disabled: isStopped || planBased || isCompleted || prescriptionsStatus === "draft",
                  },
                ]
              : []),
            ...(complete
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faCheck} />,
                    label: "Complete",
                    command: () => complete(medicationRequestInfo.id ?? ""),
                    disabled: isCompleted || planBased || isStopped || prescriptionsStatus === "draft",
                  },
                ]
              : []),
            ...(discard
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faTrashCan} />,
                    label: "Discard",
                    command: () => discard(medicationRequestInfo.id ?? ""),
                    disabled: disabledAction,
                  },
                ]
              : []),
          ]
        : undefined,
  }
}
