import { RelatedPerson, Coverage } from "fhir"
import { startOfToday } from "date-fns"
import * as Yup from "yup"

import { humanNameSchema, telecomSchema } from "utils"
import { ContactPointSystem, emptyAddress, insuranceRelationship } from "data"
import { CoverageData } from "coverage/types"

const HIP_TYPE = {
  coding: [
    {
      code: "HIP",
      system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
      display: "health insurance plan policy",
    },
  ],
  text: "health insurance plan policy",
}

const GROUP_TYPE = {
  coding: [{ code: "group", system: "http://terminology.hl7.org/CodeSystem/coverage-class", display: "Group" }],
  text: "Group",
}

const getCoverageInitialValues = (patientId: string): Coverage => ({
  type: HIP_TYPE,
  payor: [{ id: undefined, display: undefined, resourceType: "Organization" }],
  status: "active",
  beneficiary: { id: patientId, resourceType: "Patient" },
  subscriber: { id: patientId, resourceType: "Patient" },
  subscriberId: "",
  relationship: {
    text: insuranceRelationship.self.display,
    coding: [insuranceRelationship.self],
  },
  period: {
    start: startOfToday().toISOString(),
  },
  class: [
    {
      type: GROUP_TYPE,
      name: "Group Number",
      value: "",
    },
  ],
})

const sanitizeCoverage = (coverage: CoverageData) => {
  if (coverage.relationship?.coding?.[0].code === insuranceRelationship.self.code)
    coverage.subscriber = { ...coverage.beneficiary }
  coverage.period = {
    start: new Date(coverage.period?.start as string).toISOString(),
  }

  delete coverage.relatedPerson

  return coverage
}

const getRelatedPersonInitialValues = (patientId: string): RelatedPerson => ({
  patient: { id: patientId, resourceType: "Patient" },
  relationship: [{ coding: undefined }],
  name: [{ use: "official", given: [""], family: "" }],
  gender: "unknown",
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: "" },
    { system: ContactPointSystem.phone, use: "home", value: "" },
  ],
  address: [emptyAddress],
})

const relatedPersonValidationSchema = Yup.object().shape({
  name: Yup.array(humanNameSchema),
  telecom: Yup.array(telecomSchema),
  relationship: Yup.array(
    Yup.object().test("empty-coding", "Relationship is required", (value) => value.coding !== undefined),
  ),
})

const coverageValidationSchema = Yup.object().shape({
  type: Yup.object().test("empty-coding", "Type is required", (value) => value.coding !== undefined),
  payor: Yup.array().of(
    Yup.object().test("payor", "Carrier is required", (value) => value !== undefined && value?.id !== undefined),
  ),
  subscriberId: Yup.string().required("Subscriber ID is required"),
  period: Yup.object().shape({
    start: Yup.string().required("Period start is required"),
  }),
  subscriber: Yup.object().test("check-person", "Subscriber data is required", (value, context) => {
    const { relationship } = context.parent
    return (
      relationship?.coding?.[0]?.code === insuranceRelationship.self.code ||
      (value !== undefined && value?.id !== undefined && value?.resourceType === "RelatedPerson")
    )
  }),
  class: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().test("Group", "Group Number is required", (value) => !!value),
    }),
  ),
})

export {
  getCoverageInitialValues,
  coverageValidationSchema,
  getRelatedPersonInitialValues,
  relatedPersonValidationSchema,
  sanitizeCoverage,
}
