import { Appointment, CodeableConcept, HealthcareService, Location, Patient, Practitioner, Reference, Slot } from "fhir"

export type CalendarAppointment = {
  appointment: Appointment
  practitioner?: Practitioner
  patient?: Patient
  location?: Location
  healthcareService?: HealthcareService
}

export type AppointmentFormData = Appointment & {
  selectedSlot?: Partial<Slot>
  questionnaires?: string[]
  patient?: Reference
  practitioner?: Reference
  location?: Reference
  room?: Reference
  devices: Reference[]
  healthcareService?: Reference
}

export type MonthBounds = { start: Date; end: Date }

export type AppointmentType = HealthcareService & {
  practitioners?: Reference[]
  rooms?: Reference[]
  devices?: Reference[]
  telemedicine?: boolean
  patientCanBook?: boolean
  daysInAdvance?: number
  icd10?: CodeableConcept[]
  questionnaires?: Reference[]
  color?: string
}

export const APPOINTMENT_TYPE_CHARACTERISTIC = {
  telemedicine: "telemedicine",
  patientCanBook: "patient-can-book",
  color: "http://chartedhealth.com/fhir/healthcare-service-characteristic/color",
}
