import { useFormikContext } from "formik"
import { FC } from "react"

import { ReferenceDropdownField, TextAreaField } from "commons"

import { useAppointmentParticipants } from "../hooks"
import { AppointmentFormData } from "../types"
import Devices from "./Devices"

const AppointmentParticipantsSection: FC<Props> = ({ hideExtraParticipantFields }) => {
  const {
    values: { healthcareService, selectedSlot },
  } = useFormikContext<AppointmentFormData>()
  const { devicesRefs, roomsRefs, isLoading } = useAppointmentParticipants({
    appointmentType: healthcareService,
    startDate: selectedSlot?.start,
    endDate: selectedSlot?.end,
  })

  const fieldsDisabled = !selectedSlot

  return (
    <fieldset
      className="flex flex-col gap-2 disabled:opacity-40 disabled:pointer-events-none"
      disabled={fieldsDisabled}
    >
      {!hideExtraParticipantFields && (
        <>
          <ReferenceDropdownField
            field="room"
            label="Room"
            options={roomsRefs}
            className="w-full"
            isClearable
            disabled={fieldsDisabled}
            loading={isLoading}
            showFilter={false}
            validation={(value) => !!roomsRefs.length && !value && "Room is required"}
          />
          <Devices options={devicesRefs} disabled={fieldsDisabled || !!devicesRefs.length} />
        </>
      )}
      <TextAreaField field="comment" label="Notes" rows={5} />
    </fieldset>
  )
}

type Props = {
  hideExtraParticipantFields?: boolean
}

export { AppointmentParticipantsSection }
