import { useQuery } from "@tanstack/react-query"
import {
  asReference,
  Coverage,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  Patient,
  Reference,
  RelatedPerson,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { coverageKeys } from "../query-keys"
import { CoverageData } from "../types"

const useCoverages = (patientId: string) => {
  const { search } = useClient()
  const queryKey = coverageKeys.withPatientId(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _count: "20",
        _include: "subscriber,beneficiary",
        status: "active",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Coverage`, filters })

      const coverages = getResources<Coverage>(bundle, "Coverage")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")
      const relatedPersons = getResourcesByTypeAsIndex<RelatedPerson>(bundle, "RelatedPerson")

      //const payors = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")

      return { coverages, patients, relatedPersons }
    },
    meta: { context: { queryKey } },
  })

  const { coveragesList, coverageRefs, coverages } = useMemo(() => {
    const coveragesList = data?.coverages?.map((coverage) => coverage?.payor?.[0]?.display).join(", ")
    const coverageRefs = data?.coverages.map<Reference>((coverage) => asReference(coverage))
    const coverages = data?.coverages.reduce((acc, coverage) => {
      const subscriber =
        data?.patients?.[coverage.subscriber?.id as string] ?? data.relatedPersons?.[coverage.subscriber?.id as string]
      const beneficiary = data?.patients?.[coverage.beneficiary?.id as string]
      //const payor = data?.payors?.[coverage.payor?.[0]?.id as string]

      return [
        ...acc,
        {
          ...coverage,
          subscriber: {
            ...coverage.subscriber,
            display: subscriber?.name?.[0] ? humanNameAsString(subscriber.name[0]) : coverage.subscriber?.display,
          },
          beneficiary: {
            ...coverage.beneficiary,
            display: beneficiary?.name?.[0] ? humanNameAsString(beneficiary.name[0]) : coverage.beneficiary?.display,
          },
          /* payor: [
            {
              ...coverage.payor?.[0],
              address: payor.address?.[0],
            },
          ], */
        } as CoverageData,
      ]
    }, Array<CoverageData>())
    return { coveragesList, coverageRefs, coverages }
  }, [data?.coverages, data?.patients])

  return {
    isLoading,
    coverages: coverages ?? [],
    coveragesList,
    coverageRefs,
  }
}

export { useCoverages }
