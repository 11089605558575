import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Address } from "fhir"
import { addressStringify, getAddressByType, getHomeAddress } from "fhir/utils"
import { FormikHelpers, useFormikContext } from "formik"

import {
  AddressField,
  AddressVerificationFeedback,
  useSmartyAddressVerification,
  useSmartyAddressVerificationContext,
} from "commons"
import { ProfileSection, emptyAddress } from "data"
import { useLoginContext } from "security"
import { areAddressesSimilars, getAddressSchema } from "utils"

import { useUpdatePatientAddress } from "../hooks"
import { InformationCard } from "./InformationCard"
import { useHomeContext } from "home"

const AddressInformation = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { patientOrganizations } = useHomeContext()
  const { updateAddress, isUpdating } = useUpdatePatientAddress()
  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const [isSameAsPractice, setIsSameAsPractice] = useState(false)

  const { address: patientAddress } = loggedInPatient

  const onSubmit = async (address: Address, formikHelpers?: FormikHelpers<Address>) => {
    await checkAddress(address, formikHelpers, () => {
      clearVerificationInfo()
      updateAddress({ address, patient: loggedInPatient })
    })
  }

  const homeAddress = getHomeAddress(patientAddress)
  const shippingAddress = getAddressByType(patientAddress, "postal")
  const physicalAddress = getAddressByType(patientAddress, "physical")

  const organizationAddress = useMemo(
    () => patientOrganizations?.[loggedInPatientId]?.address?.[0],
    [patientOrganizations],
  )

  const checkNotPracticeAddress = useCallback(
    (address: Address) => {
      if (organizationAddress && areAddressesSimilars(address, organizationAddress)) {
        setIsSameAsPractice(true)
        return SAME_AS_PRACTICE_ERROR
      } else {
        setIsSameAsPractice(false)
        return undefined
      }
    },
    [organizationAddress],
  )

  return (
    <InformationCard
      id={ProfileSection.ADDRESS}
      title="Address Information"
      data={{
        Home: addressStringify(homeAddress),
        Shipping: addressStringify(shippingAddress),
        Physical: addressStringify(physicalAddress),
      }}
      lineEditData={[
        homeAddress ?? emptyAddress,
        shippingAddress ?? { ...emptyAddress, type: "postal" },
        physicalAddress ?? { ...emptyAddress, type: "physical" },
      ]}
      initialValue={emptyAddress}
      validationSchema={getAddressSchema()}
      onClose={clearVerificationInfo}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
      className="profile-card-section"
      disableSave={isSameAsPractice}
    >
      <AddressFieldWithVerification checkValidAddress={checkNotPracticeAddress} />
      {isSameAsPractice && <small className="text-red-500">{SAME_AS_PRACTICE_ERROR}</small>}
    </InformationCard>
  )
}

const AddressFieldWithVerification: FC<AddressFieldProps> = ({ checkValidAddress }) => {
  const { values, setFieldValue, setFieldError } = useFormikContext<Address>()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()

  useEffect(() => {
    const error = checkValidAddress?.(values)
    if (error) setFieldError("line[0]", error)
  }, [values])

  return (
    <div className="relative p-fluid grid gap-4">
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
        <AddressField context="Address" />
      </fieldset>
      <AddressVerificationFeedback
        addressVerificationInfo={addressVerificationInfo}
        handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue)}
        handleBypassAddressValidation={bypassAddressValidation}
      />
    </div>
  )
}

const SAME_AS_PRACTICE_ERROR = "⚠️ Your address can't be the same as the practice address"

type AddressFieldProps = {
  checkValidAddress?: (address: Address) => string | undefined
}

export { AddressInformation }
