import { useMutation } from "@tanstack/react-query"
import { DocumentReference } from "fhir"

import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { useClient } from "api"
import { openLinkInNewTab } from "utils"

const usePrintInvoice = () => {
  const { operationRequest, getSignedUrl } = useClient()

  const print = async (invoiceId: string): Promise<DocumentReference> => {
    return operationRequest({ endpoint: `Invoice/${invoiceId}`, method: "POST", operation: "pdfize" })
  }

  const { mutate: printInvoice, isPending: isPrinting } = useMutation({
    mutationFn: print,
    onSuccess: async (response: DocumentReference) => {
      if (response.content[0].attachment.url) {
        const { url } = await getSignedUrl(response.content[0].attachment.url)
        openLinkInNewTab(url)
      }
    },
    onError: (error: CustomError) => {
      displayNotificationError(error)
    },
  })

  return { printInvoice, isPrinting }
}

export { usePrintInvoice }
