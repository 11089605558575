import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DialogFormContainer, InputField } from "commons"

const CancelOrderConfirmation = ({
  orderId,
  hasDropShip,
  invoiceNumber,
  showConfirm = orderId !== undefined,
  onHide,
  onSubmit,
}: Props) => (
  <DialogFormContainer
    title="Cancel order"
    showForm={showConfirm}
    onSubmit={onSubmit}
    onCancel={onHide}
    saveLabel="Cancel order"
    useFormik
    initialValue={{ orderId, reason: "", stopOrder: false || !hasDropShip }}
    className="space-y-0"
  >
    {invoiceNumber && (
      <div className="text-orange-500 pb-4 flex items-center">
        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
        <div className="pl-2 text-sm">
          <div className="leading-4">The order you are trying to cancel is paid</div>
          <div className="leading-4">Invoice identifier: {invoiceNumber}</div>
        </div>
      </div>
    )}
    <InputField
      field="reason"
      label="Please provide a reason"
      className="mb-0"
      validation={(value) => !value && "Reason is required"}
    />
    {hasDropShip && <InputField field="stopOrder" type="checkbox" label="Cancel all future orders" horizontal />}
  </DialogFormContainer>
)

type Props = {
  orderId?: string
  hasDropShip: boolean
  invoiceNumber?: string
  showConfirm?: boolean
  onHide(): void
  onSubmit(data: { orderId: string; reason: string; stopOrder: boolean }): void
}

export { CancelOrderConfirmation }
