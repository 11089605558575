import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DatesSetArg, EventContentArg, createPlugin } from "@fullcalendar/core"
import { ViewProps } from "@fullcalendar/core/internal"
import DayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/react"
import TimeGridPlugin from "@fullcalendar/timegrid"
import { format, isSameDay } from "date-fns"
import { asReference } from "fhir"
import { CalendarMonthChangeEvent } from "primereact/calendar"
import { FC, createRef, useMemo, useState } from "react"
import { classNames } from "primereact/utils"
import { Tooltip } from "primereact/tooltip"
import { faCalendar, faClock, faUser, faUserDoctor } from "@fortawesome/pro-regular-svg-icons"

import {
  AppointmentFormOverlay,
  getMonthDateBounds,
  sanitize,
  useBookAppointment,
  usePatientAppointments,
  useUnbookAppointment,
  useUpdateAppointment,
  initialValues as getInitialValues,
} from "appointments"
import { Button, ConfirmDialog, useCrudReducer, useSidebarContext } from "commons"
import { useLoginContext } from "security"
import { formatsByTypes } from "data"
import { AppointmentFormData } from "appointments/types"

import { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents } from "../utils"
import { AgendaView } from "./AgendaView"
import { AppointmentCalendar } from "./AppointmentCalendar"
import "./CalendarView.css"

const CalendarView: FC = () => {
  const { loggedInPatientId, loggedInPatient } = useLoginContext()
  const { isSmallScreen, location } = useSidebarContext()
  const calendarRef = createRef<FullCalendar>()

  const [datesSet, setDatesSet] = useState<DatesSetArg>()
  const datesRange = getAppointmentsDateRangeFromDatesSet(datesSet)
  const calendarDate = getCalendarDateFromDatesSet(datesSet)

  const {
    initialValue,
    isNew,
    showSlide: showOverlayForm,
    removeItem: confirmUnbookItem,
    reset,
    add: addAppointmentAction,
    remove: unbook,
    hardReset,
  } = useCrudReducer({ defaultEntity: getInitialValues({ patientRef: asReference(loggedInPatient), location }) })

  const { appointments, isLoading } = usePatientAppointments({
    patientId: loggedInPatientId,
    start: datesRange?.start,
    end: datesRange?.end,
  })

  const events = useMemo(() => getEvents(appointments), [appointments])

  const { bookAppointment } = useBookAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset)
  const { unbookAppointment, isUnbooking } = useUnbookAppointment({ onSettled: hardReset })

  const getCurrentAppointmentsDate = (date: Date) =>
    events.filter((ev) => ev.start && isSameDay(new Date(ev.start.toString()), date))

  const onSubmit = (appointment: AppointmentFormData) => {
    isNew ? bookAppointment(sanitize(appointment)) : updateAppointment(sanitize(appointment))
  }
  const onUnbook = (appointment: AppointmentFormData) => unbookAppointment(appointment.id)

  const handleCalendarMonthChange = ({ month, year }: CalendarMonthChangeEvent) => {
    const { start } = getMonthDateBounds({ month, year })
    calendarRef.current?.getApi().gotoDate(start)
    if (!isSmallScreen) calendarRef.current?.getApi().changeView("dayGridMonth")
  }

  const handleDateSelection = (date?: Date) => {
    if (date) {
      calendarRef.current?.getApi().gotoDate(date)
      calendarRef.current?.getApi().changeView("agenda")
    }
  }

  // passes props to AgendaPlugin
  class MorePropsToView {
    transform(viewProps: ViewProps) {
      return {
        ...viewProps,
        className: isSmallScreen
          ? "px-3 shadow-none border-none rounded-none grow overflow-y-auto"
          : "pl-3 border rounded-md border-solid border-slate-200 shadow-md grow overflow-y-auto",
        unbook,
      }
    }
  }

  const AgendaPlugin = useMemo(
    () =>
      createPlugin({
        name: "AgendaView",
        views: {
          agenda: AgendaView,
        },
        viewPropsTransformers: [MorePropsToView],
      }),
    [isSmallScreen, MorePropsToView],
  )

  const loadingOverlay = (
    <div className="absolute w-full h-full z-10 pt-10 pb-2 pr-2">
      <div className="bg-gray-300/55 rounded-md backdrop-blur-sm flex m-auto w-full h-full items-center justify-center">
        <span className="text-center text-white">
          <FontAwesomeIcon icon={faCircleNotch} className="h-5 w-5 mr-1" spin />
          <span>Loading appointments...</span>
        </span>
      </div>
    </div>
  )

  const renderEventContent = (eventInfo: EventContentArg) => {
    const appointment = eventInfo.event._def.extendedProps.appointment as AppointmentFormData

    return (
      <div className="flex flex-1 justify-between items-center relative group" id={`appt_${appointment.id}`}>
        <div className="grid grid-flow-col-dense items-center gap-1">
          <span
            className="col-span-1 border-[3px] rounded-full h-1.5 w-1.5"
            style={{ borderColor: eventInfo.borderColor }}
          />
          <p className="col-span-3 truncate text-gray-600 text-xs">{eventInfo.event.title}</p>
          <span className="col-span-1 text-gray-400 text-xs self-end">{eventInfo.timeText}</span>
        </div>

        <Tooltip
          target={`#appt_${appointment.id}`}
          pt={{
            arrow: { className: "hidden" },
            text: { className: "bg-white p-1 border border-gray-200 shadow-md min-w-56" },
          }}
          position="mouse"
          autoHide={false}
        >
          <div className="flex flex-col gap-3 text-sm pl-2">
            <div className="flex items-center gap-2">
              <span
                className="border-[3px] rounded-full mx-1 w-1.5 h-1.5"
                style={{ borderColor: eventInfo.borderColor }}
              ></span>
              <span className="text-gray-600">{eventInfo.event._def.extendedProps.appointmentType}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-400">
              <FontAwesomeIcon icon={faUserDoctor} />
              <span>{eventInfo.event._def.extendedProps.practitionerName}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-400">
              <FontAwesomeIcon icon={faUser} />
              <span>{eventInfo.event._def.extendedProps.patientName}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-400">
              <FontAwesomeIcon icon={faCalendar} />
              <span>{format(eventInfo.event.start as Date, formatsByTypes.LONG_DATE)}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-400">
              <FontAwesomeIcon icon={faClock} />
              <span>{eventInfo.timeText}</span>
            </div>
            <div className="flex justify-end gap-2 mt-2">
              <Button label="Unbook" buttonStyle="text" className="!text-red-500" onClick={() => unbook(appointment)} />
              {/* <Button label="Details" buttonStyle="text" className="!text-gray-600" /> */}
            </div>
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="p-2 bg-white h-full flex">
      {!isSmallScreen && (
        <div className="flex-1 pl-2 pt-2 relative">
          {isLoading && loadingOverlay}
          <FullCalendar
            ref={calendarRef}
            plugins={[DayGridPlugin, TimeGridPlugin, AgendaPlugin]}
            initialView="agenda"
            events={events}
            headerToolbar={{
              left: "title",
              right: "prev,today,next dayGridMonth timeGridWeek agenda",
            }}
            buttonText={{ month: "Month", week: "Week", agenda: "Agenda" }}
            height="100%"
            eventMouseEnter={(mouseEnterInfo) => {
              const el = mouseEnterInfo?.el
              el.setAttribute("title", mouseEnterInfo?.event?.title)
            }}
            datesSet={(dates) => {
              if (dates?.startStr !== datesSet?.startStr) {
                setDatesSet(dates)
              }
            }}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }}
            eventContent={renderEventContent}
          />
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col",
          isSmallScreen ? "w-full h-full relative gap-6 px-3" : "pl-3 flex-none w-2/6 md:w-[35%] 2xl:w-1/4",
        )}
      >
        {isSmallScreen && (
          <Button
            label="Schedule appointment"
            className="block outline-none ring-0 w-full text-white p-3 mt-6 button-primary"
            onClick={addAppointmentAction}
          />
        )}
        <div className="relative flex">
          {isLoading && loadingOverlay}
          <AppointmentCalendar
            currentDate={calendarDate}
            selectDate={handleDateSelection}
            currentDateAppointments={getCurrentAppointmentsDate}
            onMonthChange={handleCalendarMonthChange}
          />
        </div>
        {isSmallScreen && (
          <FullCalendar
            ref={calendarRef}
            plugins={[AgendaPlugin]}
            initialView="agenda"
            events={events}
            headerToolbar={{
              left: "",
              center: "title",
              right: "",
            }}
            height="100%"
            eventMouseEnter={(mouseEnterInfo) => {
              const el = mouseEnterInfo?.el
              el.setAttribute("title", mouseEnterInfo?.event?.title)
            }}
            datesSet={(dates) => {
              if (dates?.startStr !== datesSet?.startStr) {
                setDatesSet(dates)
              }
            }}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }}
            eventContent={renderEventContent}
          />
        )}
        {!isSmallScreen && (
          <Button
            label="Schedule appointment"
            className="block outline-none ring-0 w-full text-white p-3 mt-4 button-primary"
            onClick={addAppointmentAction}
          />
        )}

        <AppointmentFormOverlay
          visible={showOverlayForm}
          isEditing={!isNew}
          appointment={initialValue}
          onHide={reset}
          onSubmit={onSubmit}
        />

        <ConfirmDialog
          confirmText="Are you sure you want to unbook this appointment"
          actionName="Unbook"
          visible={confirmUnbookItem !== undefined}
          onConfirm={() => onUnbook(confirmUnbookItem as AppointmentFormData)}
          hideDialog={() => unbook(undefined)}
          isLoading={isUnbooking}
        />
      </div>
    </div>
  )
}

export { CalendarView }
