import { useReducer } from "react"

import { MedicationRequestData } from "commons"

const initialState = {
  statusFilter: ["active", "draft", "on-hold", "completed", "stopped"],
  searchFilter: undefined,
  perPage: 20,
  page: 1,
  itemActionClicked: "",
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "action-cliked" | "active-filters" | "change-per-page" | "change-page" | "update-search-filter"
    payload: Array<string> | number | string | MedicationRequestData | undefined
  },
) => {
  switch (type) {
    case "action-cliked":
      return { ...state, itemActionClicked: payload as string }
    case "active-filters":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "change-per-page":
      return {
        ...state,
        perPage: payload as number,
      }
    case "change-page":
      return {
        ...state,
        page: payload as number,
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useMedReducerState = (init?: Partial<State>) => {
  const [{ itemActionClicked, statusFilter, searchFilter, perPage, page }, dispatch] = useReducer(reducer, {
    ...initialState,
    ...init,
  })

  const onActionCliked = (medicationId: string) => {
    dispatch({ type: "action-cliked", payload: medicationId })
  }

  const updateFilters = (filters: string[]) => {
    dispatch({ type: "active-filters", payload: filters })
  }

  const changePerPage = (perPage: number) => {
    dispatch({ type: "change-per-page", payload: perPage })
  }

  const changePage = (page: number) => {
    dispatch({ type: "change-page", payload: page })
  }

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  return {
    itemActionClicked,
    statusFilter,
    searchFilter,
    perPage,
    page,
    onActionCliked,
    updateFilters,
    updateSearchText,
    changePerPage,
    changePage,
  }
}

type State = {
  statusFilter: Array<string>
  searchFilter?: string
  perPage: number
  page: number
  itemActionClicked: string
}

export { useMedReducerState }
