import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConfirmDialog } from "primereact/confirmdialog"
import { SelectButton } from "primereact/selectbutton"
import { startTransition, useId, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSearchParams } from "react-router-dom"
import { MenuItem } from "primereact/menuitem"
import { faCancel, faPills } from "@fortawesome/pro-solid-svg-icons"

import { SearchWithStatus, SkeletonLoader } from "commons"
import { medicationRequestOrdersStatus } from "data"
import { useLoginContext } from "security"
import {
  useMedReducerState,
  useMrOrders,
  MedicationOrderListItem,
  MedicationRequestOrderData,
  usePrescriptionMrOrder,
  useCancelMrOrder,
} from "commons/meds"

import { Contents } from "../../types"
import { CancelOrderConfirmation } from "./CancelOrderConfirmation"

const MedicationOrdersList = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { loggedInPatientId } = useLoginContext()
  const [params, setParams] = useSearchParams()
  const [orderToCancel, setOrderToCancel] = useState<MedicationRequestOrderData>()

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const goToOrderDetails = (orderId: string) => {
    params.set("order", orderId)
    setParams(params)
  }

  const { statusFilter, itemActionClicked, searchFilter, onActionCliked, updateFilters, updateSearchText } =
    useMedReducerState({ statusFilter: ["completed", "revoked"] })
  const {
    mrOrderData: orders,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useMrOrders(loggedInPatientId, "nutraceutical-order", statusFilter, searchFilter)

  const { cancelMrOrder, isCancelling } = useCancelMrOrder("nutraceutical-order", () => setOrderToCancel(undefined))
  const cancelOrder = (orderId: string, reason: string, stopOrder: boolean) => {
    onActionCliked(orderId)
    cancelMrOrder({
      patientId: loggedInPatientId,
      serviceRequestId: orderId,
      cancelReason: reason,
      stopSequence: stopOrder,
    })
  }

  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()
  const onPrescription = (order: MedicationRequestOrderData) => {
    onActionCliked(order.serviceRequest.id as string)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string })
  }

  const activeActions = (mrOrderData: MedicationRequestOrderData): MenuItem[] => [
    {
      label: "Prescription",
      icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
      command: () => onPrescription(mrOrderData),
    },
    {
      label: "Cancel",
      icon: <FontAwesomeIcon icon={faCancel} size="sm" className="mr-2" />,
      command: () => setOrderToCancel(mrOrderData),
      disabled: mrOrderData.invoices?.some(({ status }) => status === "balanced"),
    },
  ]

  return (
    <div className="module-container flex flex-col h-full relative overflow-auto p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search orders"
          options={medicationRequestOrdersStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!orders?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No orders found</p>
            </div>
          ) : (
            <div className="bg-white flex flex-col h-full overflow-auto">
              <InfiniteScroll
                loadMore={() => fetchNextPage()}
                hasMore={hasNextPage}
                initialLoad={false}
                useWindow={false}
                loader={loader()}
                className="mr-1"
              >
                {orders.map((order) => (
                  <MedicationOrderListItem
                    key={order.serviceRequest.id}
                    mrOrderData={order}
                    isLoading={itemActionClicked === order.serviceRequest.id && (isCancelling || isGettingPrescription)}
                    onItemClicked={() => goToOrderDetails(order.serviceRequest.id as string)}
                    dropdownMenuItems={activeActions}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
      <ConfirmDialog />
      <CancelOrderConfirmation
        orderId={orderToCancel?.serviceRequest?.id}
        hasDropShip={orderToCancel?.hasDropShip ?? false}
        invoiceNumber={
          orderToCancel?.invoices?.[0]?.status === "balanced"
            ? orderToCancel?.invoices?.[0]?.identifier?.[0]?.value
            : undefined
        }
        onHide={() => setOrderToCancel(undefined)}
        onSubmit={({ orderId, reason, stopOrder }) => cancelOrder(orderId, reason, stopOrder)}
      />
    </div>
  )
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

export { MedicationOrdersList }
