import { faCalendarDays, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, isValid } from "date-fns"
import { Chip } from "primereact/chip"

import { formatsByTypes } from "data"
import { AvatarImage, AvatarListItem } from "commons"

const AgendaListItem = ({
  patientAvatar,
  patientName,
  appointmentStart,
  appointmentDescription,
  appointmentType,
  color,
  appointmentStatus,
  action,
  onUnbook,
}: Props) => {
  const head = (
    <>
      <span title="Patient">{patientName}</span>
      {appointmentStatus && (
        <span title="Status">
          <Chip label={appointmentStatus} className="ml-2 custom-chip" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <span className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1 text-gray-400" />
        <span title="Start">
          {appointmentStart && isValid(appointmentStart)
            ? format(appointmentStart, formatsByTypes.LONG_DATETIME)
            : "No date"}
        </span>
      </span>
      {appointmentDescription && (
        <span title="Description" className="pl-2">
          {appointmentDescription}
        </span>
      )}
      {appointmentType && (
        <span title="Type" className="font-bold capitalize pl-2" style={{ color }}>
          {appointmentType}
        </span>
      )}
    </>
  )

  return (
    <AvatarListItem
      avatarImg={<AvatarImage name={patientName} photoUrl={patientAvatar} className="h-10 w-10 rounded-full" />}
      header={head}
      subHeader={details}
      actionItem={{
        label: "Unbook",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" title="Unbook" />,
        disabled: appointmentStatus === "cancelled",
        command: onUnbook,
      }}
      onContentClick={action}
    />
  )
}

type Props = {
  appointmentStart?: Date
  appointmentDescription?: string
  appointmentStatus?: string
  patientAvatar?: string
  patientName: string
  patientId?: string
  appointmentType?: string
  color?: string
  action?(): void
  onUnbook?(): void
}

export { AgendaListItem }
