const patientAptsQueryKeys = {
  all: ["patient/appointments"] as const,
  list: (patientId: string) => [...patientAptsQueryKeys.all, patientId],
  scheduled: (patientId: string) => ["scheduledAppointment", patientId],
  availableSlots: (patientId?: string, appointmentTypeId?: string, start?: Date, end?: Date) => [
    "appointments/available-slots",
    patientId,
    appointmentTypeId,
    start,
    end,
  ],
  availableParticipants: (appointmentTypeId?: string, start?: Date, end?: Date) => [
    "appointments/available-participants",
    appointmentTypeId,
    start,
    end,
  ],
  appointmentTypes: (organizationId: string) => ["appointment-types", organizationId],
}

const practitionerAptsQueryKeys = {
  all: (
    currentOrganizationId: string,
    start?: Date,
    end?: Date,
    patientId?: string[],
    practitionerId?: string[],
    appointmentType?: string[],
  ) => ["appointments", currentOrganizationId, start, end, patientId, practitionerId, appointmentType].filter(Boolean),
}

export { patientAptsQueryKeys, practitionerAptsQueryKeys }
