import { BILLING_TYPES_CODES, type MED_FEE_TYPE } from "data"
import { MedicationDosage } from "eprescribe/types"
import {
  Coding,
  MedicationDispense,
  MedicationKnowledge,
  MedicationKnowledgeAdministrationGuidelinesArray,
  MedicationRequest,
  Money,
  Practitioner,
  PractitionerRole,
  Quantity,
  Reference,
  ServiceRequest,
} from "fhir"

export enum MenuStyles {
  /** Show menu as in a dropdown */
  Dropdown,
  /** Show menu items inline */
  ActionItems,
  /** Only show first menu item with faExternalLink icon */
  ExternalAction,
}

export type PractitionerInfo = {
  practitioner: Practitioner
  practitionerRole: PractitionerRole
  practitionerRoleRef?: Reference
  practitionerRoles?: PractitionerRole[]
  hasDosespotSetup: boolean
}

export enum RoundedStyles {
  None = 0,
  Left = 1,
  Right = 2,
  Both = Left | Right,
  Full_Left = 4,
  Full_Right = 8,
  Full = Full_Left | Full_Right,
}

export type CustomError = Error & { cause: { name: string; message: string; logoutFlag?: boolean; traceId?: string } }

export type FieldErrorType = [string, string | object | []]

export type MedicationRequestData = {
  medicationRequestInfo: MedicationRequestInfo
  medicationKnowledge: MedicationKnowledge | undefined
  pricePerUnit?: Money
  medicationDispense?: MedicationDispense[]
  serviceRequest?: ServiceRequest
  patientPrice?: Money
  requesterRef?: Reference
}

export type MedicationRequestInfo = MedicationRequest & {
  //nutra props
  medicationUnit?: string
  doseQuantity?: string
  treatmentFrequency?: string
  unitsByRecipient?: number
  //Rx props
  prescriptionQuantity?: Quantity
  dosages?: MedicationDosage[]
  administrationGuideline?: MedicationKnowledgeAdministrationGuidelinesArray
  //commons props
  requesterRef?: Reference
}

export type BaseMedicationFee = {
  value: number
  type: MED_FEE_TYPE
}

export type MedicationFeeWithFrequency = BaseMedicationFee & {
  frequency: Quantity
}

export type MedicationFee =
  | (BaseMedicationFee & { type: Exclude<MED_FEE_TYPE, "by-frequency"> })
  | MedicationFeeWithFrequency

export const GENERIC_BILLING_TYPE = {
  BILL_PATIENT: BILLING_TYPES_CODES.BILL_PATIENT,
  BILL_PRACTICE_OR_INSURANCE: BILLING_TYPES_CODES.BILL_PRACTICE,
} as const

export type GENERIC_BILLING_TYPE = (typeof GENERIC_BILLING_TYPE)[keyof typeof GENERIC_BILLING_TYPE]

export const PRODUCT_CONFIGURATION_BILLING_TYPE = {
  ...GENERIC_BILLING_TYPE,
  BOTH: "both",
} as const

export type PRODUCT_CONFIGURATION_BILLING_TYPE =
  (typeof PRODUCT_CONFIGURATION_BILLING_TYPE)[keyof typeof PRODUCT_CONFIGURATION_BILLING_TYPE]

export type ProductConfigurationToRequest = {
  code: Coding
  billingType: PRODUCT_CONFIGURATION_BILLING_TYPE
  quantity?: number
  frequency?: Quantity
}

export type ProductConfiguration = {
  code: string
  billingType: GENERIC_BILLING_TYPE
  quantity?: number
  frequency?: string
}
