import { Reference } from "fhir"
import { AutoComplete } from "primereact/autocomplete"
import { useEffect, useState, type FC } from "react"
import { useFormikContext } from "formik"

import { GenericFieldArray } from "commons"

import { AppointmentFormData } from "../types"

const Devices: FC<Props> = ({ disabled, options }) => {
  const {
    values: { devices },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const [filteredDevices, setFilteredDevices] = useState<Reference[]>(options)
  const [filter, setFilter] = useState("")

  const filterDevices = () => {
    setFilteredDevices(
      options.filter((dev) => dev?.display?.includes(filter) && !devices.some(({ id }) => id === dev?.id)),
    )
  }

  const optionKeys = new Set(options?.flatMap(({ id }) => id))

  useEffect(() => {
    setFilteredDevices(options)
    setFieldValue(
      "devices",
      !devices.length ? options : devices.filter((dev) => options.some(({ id }) => id === dev.id)),
    )
  }, [Array.from(optionKeys).toString()])

  return (
    <GenericFieldArray
      field="devices"
      label="Devices"
      itemModelBuilder={(item: Reference) => ({ leftData: [{ lineItems: [{ value: item.display }] }] })}
      addButtonVisible={false}
      emptyMessageVisible={false}
      className="field flex flex-col !mb-2"
      labelClassName="text-sm mb-2"
      containerClassName="ml-3"
      itemClassName="!py-2 !text-gray-500"
      readOnly
    >
      {({ push }) => (
        <AutoComplete
          className="p-inputtext-sm mb-3"
          suggestions={filteredDevices}
          itemTemplate={(item) => item?.display}
          showEmptyMessage
          emptyMessage="No devices found"
          onChange={(e) => {
            push(e.value)
            setFilter("")
          }}
          disabled={disabled}
          completeMethod={filterDevices}
          value={filter}
          dropdown
        />
      )}
    </GenericFieldArray>
  )
}

type Props = { disabled?: boolean; options: Reference[] }

export default Devices
