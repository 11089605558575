import { useQuery } from "@tanstack/react-query"
import { formatDate, parseISO } from "date-fns"
import { Bundle, Device, Location, Parameters, Practitioner, Reference, asReference, getResources } from "fhir"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { toZonedTime } from "date-fns-tz"
import { patientAptsQueryKeys } from "../query-keys"

const useAppointmentParticipants = ({
  appointmentType,
  startDate,
  endDate,
}: {
  appointmentType?: Reference
  startDate?: Date
  endDate?: Date
}) => {
  const { operationRequest } = useClient()
  const queryKey = patientAptsQueryKeys.availableParticipants(appointmentType?.id, startDate, endDate)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const start = formatDate(
        toZonedTime(parseISO(new Date(startDate!).toISOString()), "UTC"),
        formatsByTypes.ISO_8601_DATETIME,
      )
      const end = formatDate(
        toZonedTime(parseISO(new Date(endDate!).toISOString()), "UTC"),
        formatsByTypes.ISO_8601_DATETIME,
      )

      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "start",
            value: {
              dateTime: start,
            },
          },
          {
            name: "end",
            value: {
              dateTime: end,
            },
          },
          {
            name: "appointment-type",
            value: {
              Reference: appointmentType,
            },
          },
        ],
      }

      const bundle = await operationRequest<Bundle>({
        endpoint: "Appointment",
        method: "POST",
        operation: "get-participants",
        parameters,
      })

      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const rooms = getResources<Location>(bundle, "Location")
      const devices = getResources<Device>(bundle, "Device")

      const practitionersRefs = practitioners.map(asReference)
      const roomsRefs = rooms.map(asReference)
      const devicesRefs = devices.map(asReference)

      return { practitionersRefs, roomsRefs, devicesRefs }
    },
    enabled: !!appointmentType && !!startDate && !!endDate,
    meta: { context: { queryKey } },
  })

  const practitionersRefs = data?.practitionersRefs ?? []
  const roomsRefs = data?.roomsRefs ?? []
  const devicesRefs = data?.devicesRefs ?? []

  return { practitionersRefs, roomsRefs, devicesRefs, isLoading }
}

export { useAppointmentParticipants }
